/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Header, Page, Content, ErrorPage } from '@backstage/core-components';
import { UsersTable } from './UsersTable';
import { ADMIN_EMAILS } from '../../../../../packages/app/src/common/constants';
import { State } from '../../../../../packages/app/src/store/types';

export const UsersTablePage = () => {
  const email = useSelector((state: State) => state.email);

  const isAdmin = ADMIN_EMAILS.includes(email);

  return isAdmin ? (
    <Page themeId="tool">
      <Header title="Welcome to Admin Page!" />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <UsersTable />
          </Grid>
        </Grid>
      </Content>
    </Page>
  ) : (
    <ErrorPage status="404" statusMessage="Page Not Found" />
  );
};
