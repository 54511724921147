/* eslint-disable @backstage/no-relative-monorepo-imports */
import { Request, UpdateRequestStatus } from './types';
import { getData, postData, putData } from './utils';
import {
  BACKEND_URL,
  REQUEST_ENDPOINT,
} from '../../../../packages/app/src/common/constants';

export const triggerRequest = async (request: Request, accessToken: string) => {
  return await postData(
    `${BACKEND_URL}/${REQUEST_ENDPOINT}`,
    request,
    accessToken,
  );
};

export async function sendRequestStatus(
  updateRequestStatus: UpdateRequestStatus,
  accessToken: string,
) {
  return await putData(
    `${BACKEND_URL}/${REQUEST_ENDPOINT}`,
    updateRequestStatus,
    accessToken,
  );
}

export async function getRequestStatus(requestId: string, accessToken: string) {
  return await getData(
    `${BACKEND_URL}/${REQUEST_ENDPOINT}/${requestId}`,
    accessToken,
  );
}

export async function getPendingRequests(
  email: string,
  type: string,
  accessToken: string,
) {
  return await getData(
    `${BACKEND_URL}/${REQUEST_ENDPOINT}s/${type}/${email}`,
    accessToken,
  );
}
