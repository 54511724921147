/* eslint-disable no-restricted-imports */
/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { PropsWithChildren, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import EditNoteIcon from '@material-ui/icons/EditOutlined';
import GroupTwoTone from '@material-ui/icons/GroupTwoTone';
import FlareTwoTone from '@material-ui/icons/FlareTwoTone';
import HelpRounded from '@material-ui/icons/HelpRounded';
import People from '@material-ui/icons/People';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import { setEmail, setAccessToken } from '../../store/actions';

import {
  ADMIN_EMAILS,
  REDIRECT_DOMAIN,
  SUPPORT_BUTTON_REDIRECT_LINK,
} from '../../common/constants';
import { State } from '../../store/types';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  const dispatch = useDispatch();

  const identityApi = useApi(identityApiRef);

  useMemo(() => {
    identityApi
      .getProfileInfo()
      .then(response => {
        if (response.email) {
          dispatch(setEmail(response.email));
        }
      })
      .catch(_err => {
        //
      });
  }, [dispatch, identityApi]);

  const fetchAccessToken = () => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${REDIRECT_DOMAIN}/api/auth/oauth2/refresh?optional&env=development`,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true, // To set current cookies 'oauth2-refresh-token' in the request
    };

    axios
      .request(config)
      .then(response => {
        const responseString = JSON.stringify(response.data);
        dispatch(
          setAccessToken(JSON.parse(responseString).providerInfo.accessToken),
        );
        return;
      })
      .catch(error => {
        console.log(error);
        return;
      });

    // Refresh the token before the expiry, current time to refresh expiry is set at 55 minutes
    setTimeout(() => fetchAccessToken(), 55 * 60 * 1000);
  };

  fetchAccessToken();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const Admin = () => {
  const email = useSelector((state: State) => state.email);
  const isAdmin = ADMIN_EMAILS.includes(email);

  return isAdmin ? <SidebarItem icon={People} to="admin" text="Admin" /> : null;
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem
          icon={EditNoteIcon}
          to="self-service"
          text="Self-Service"
        />
        <SidebarItem icon={GroupTwoTone} to="squad-plugin" text="Squad" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        <Admin />

        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          <SidebarItem icon={FlareTwoTone} to="lighthouse" text="LightHouse" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup>
        <SidebarItem
          text="Support"
          icon={HelpRounded}
          to={SUPPORT_BUTTON_REDIRECT_LINK}
        />
      </SidebarGroup>
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
