import {
  SET_EMAIL,
  SET_ACCESS_TOKEN,
  SET_LOGGED_IN_TIME_TOKEN,
  SET_GITHUB_REPOS,
  SET_SSO_PERMISSION_SETS_USERS_ACTION,
  SET_APPLICATION_ACCOUNTS,
  SET_CUSTOM_SCOPES,
  SET_APP_CLIENTS,
  SET_APP_CLIENT_DETAILS,
} from '../common/constants';
import {
  SetEmailAction,
  SetAccessTokenAction,
  SetLoggedInTimeAction,
  Account,
  SetGithubReposAction,
  SetApplicationAccountsAction,
  SetCustomScopesAction,
  SetAppClientsAction,
  SetAppClientDetailsAction,
  SetSSOPermissionSetsUsersAction,
  SSOPermissionSetsUser,
  CognitoAppClientDetails,
  AppClientsDict,
} from './types';

export const setEmail = (email: string): SetEmailAction => ({
  type: SET_EMAIL,
  payload: email,
});

export const setAccessToken = (accessToken: string): SetAccessTokenAction => ({
  type: SET_ACCESS_TOKEN,
  payload: accessToken,
});

export const setLoggedInTime = (
  loggedInTime: string,
): SetLoggedInTimeAction => ({
  type: SET_LOGGED_IN_TIME_TOKEN,
  payload: loggedInTime,
});

export const setGithubRepo = (githubRepos: string[]): SetGithubReposAction => ({
  type: SET_GITHUB_REPOS,
  payload: githubRepos,
});

export const setSSOPermissionSetsUsers = (
  ssoPermissionSetsUsers: SSOPermissionSetsUser[],
): SetSSOPermissionSetsUsersAction => ({
  type: SET_SSO_PERMISSION_SETS_USERS_ACTION,
  payload: ssoPermissionSetsUsers,
});

export const setApplicationAccounts = (
  applicationAccounts: Account[],
): SetApplicationAccountsAction => ({
  type: SET_APPLICATION_ACCOUNTS,
  payload: applicationAccounts,
});

export const setCustomScopes = (
  customScopes: string[],
): SetCustomScopesAction => ({
  type: SET_CUSTOM_SCOPES,
  payload: customScopes,
});

export const setAppClients = (
  appClients: AppClientsDict,
): SetAppClientsAction => ({
  type: SET_APP_CLIENTS,
  payload: appClients,
});

export const setAppClientDetails = (
  appClientDetails: CognitoAppClientDetails,
): SetAppClientDetailsAction => ({
  type: SET_APP_CLIENT_DETAILS,
  payload: appClientDetails,
});
