import { InfoCard } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

export const GroupAboutCard = () => {
  const { entity } = useEntity();

  return (
    <InfoCard title="About" variant="gridItem">
      <Typography variant="body1">{entity.metadata.about}</Typography>
    </InfoCard>
  );
};
