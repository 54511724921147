import React from 'react';

const LogoIcon = () => {
  // const classes = useStyles();

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="90.000000pt"
      height="90.000000pt"
      viewBox="-50 0 1800.000000 1.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(-300.000000,200.000000) scale(0.0500000,-0.0500000)"
        fill="#FFFFFF"
        stroke="none"
      >
        <g transform="translate(2000.000000,12500.00000) scale(0.700000,-.700000)">
          <path
            d="M7397 13463 c-4 -3 -7 -786 -7 -1740 l0 -1733 215 0 215 0 0 1525 0
1525 5795 0 5795 0 -2 -5792 -3 -5793 -5792 -3 -5793 -2 -2 1527 -3 1528 -210
0 -210 0 -3 -1738 -2 -1737 6220 0 6220 0 -2 6218 -3 6217 -6211 3 c-3416 1
-6214 -1 -6217 -5z"
          />
        </g>
        <g transform="translate(5000.000000,25.000000)">
          <path
            d="M540 7265 l0 -2345 808 0 c815 0 901 3 1142 40 90 14 260 59 390 105
450 157 824 472 1083 910 229 389 337 813 337 1329 0 858 -359 1528 -1055
1967 -270 170 -600 278 -994 324 -101 12 -282 15 -918 15 l-793 0 0 -2345z
m1608 1834 c443 -48 779 -209 1069 -512 374 -393 538 -885 494 -1482 -28 -371
-122 -675 -293 -949 -166 -265 -348 -435 -598 -561 -128 -65 -202 -91 -350
-127 -211 -50 -322 -58 -867 -58 l-493 0 0 1850 0 1850 470 0 c263 0 514 -5
568 -11z"
          />
        </g>
        <g transform="translate(-2500.000000,25.000000)">
          <path
            d="M12141 9588 c7 -13 327 -535 711 -1161 384 -626 698 -1140 698 -1142
0 -2 -232 -367 -516 -811 -738 -1155 -984 -1542 -984 -1549 0 -3 149 -4 332
-3 l332 3 576 929 c317 512 581 931 587 933 6 2 284 -417 619 -932 l608 -935
328 0 c180 0 328 2 328 4 0 3 -349 530 -777 1173 -427 642 -778 1175 -780
1183 -2 8 309 528 693 1155 383 627 700 1148 705 1158 9 16 -9 17 -323 17
l-333 0 -535 -903 c-295 -496 -539 -902 -543 -902 -5 0 -249 406 -544 903
l-535 902 -329 0 -330 0 12 -22z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
