import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const selfServicePlugin = createPlugin({
  id: 'self-service',
  routes: {
    root: rootRouteRef,
  },
});

export const SelfServicePage = selfServicePlugin.provide(
  createRoutableExtension({
    name: 'SelfServicePage',
    component: () =>
      import('./components/SelfService').then(m => m.SelfService),
    mountPoint: rootRouteRef,
  }),
);

export const DevAccountRequestForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'DevAccountRequestForm',
    component: {
      lazy: () =>
        import('./components/AccountRequestForm').then(
          m => m.DevAccountRequestForm,
        ),
    },
  }),
);

export const WorkloadAccountRequestForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'WorkloadAccountRequestForm',
    component: {
      lazy: () =>
        import('./components/AccountRequestForm').then(
          m => m.WorkloadAccountRequestForm,
        ),
    },
  }),
);

export const AwsUserAccessForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'AwsUserAccessForm',
    component: {
      lazy: () =>
        import('./components/AwsUserAccessForm').then(m => m.AwsUserAccessForm),
    },
  }),
);

export const DeployRoleForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'DeployRole',
    component: {
      lazy: () => import('./components/DeployRole').then(m => m.DeployRoleForm),
    },
  }),
);

export const CentralIdentityAPIForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'CentralIdentityAPIForm',
    component: {
      lazy: () =>
        import('./components/CentralIdentityAPIForm').then(
          m => m.CentralIdentityAPIForm,
        ),
    },
  }),
);

export const CognitoResourceServerCreationForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'CognitoResourceServerCreationForm',
    component: {
      lazy: () =>
        import('./components/CognitoResourceServerCreationForm').then(
          m => m.CognitoResourceServerCreationForm,
        ),
    },
  }),
);

export const CognitoAppClientModificationForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'CognitoAppClientModificationForm',
    component: {
      lazy: () =>
        import('./components/CognitoAppClientModificationForm').then(
          m => m.CognitoAppClientModificationForm,
        ),
    },
  }),
);

export const SubDomainForm = selfServicePlugin.provide(
  createComponentExtension({
    name: 'SubDomainForm',
    component: {
      lazy: () =>
        import('./components/SubDomainForm').then(m => m.SubDomainForm),
    },
  }),
);
