/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ItemCardHeader } from '@backstage/core-components';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import '../card-component.css';
import { Box, Divider } from '@material-ui/core';
import { StationCardDetails } from '../../../internal';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

export const StationCard = ({
  stationCardDetails,
}: {
  stationCardDetails: StationCardDetails;
}) => {
  const navigate = useNavigate();

  return (
    <Card className="card" key={stationCardDetails.cardId}>
      <Tooltip title={stationCardDetails.title}>
        <CardMedia>
          <ItemCardHeader
            subtitle={
              <div style={{ fontSize: '16px' }}>
                <strong>{stationCardDetails.title}</strong>
              </div>
            }
          />
        </CardMedia>
      </Tooltip>
      <CardContent
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
          paddingBottom: '10px',
          paddingTop: '10px',
        }}
      >
        <Box height="150px">
          <Typography variant="body1">
            <div
              dangerouslySetInnerHTML={{
                __html: stationCardDetails.description,
              }}
            />
          </Typography>
        </Box>
      </CardContent>
      <Divider variant="middle" />
      <Box
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
          paddingBottom: '10px',
          paddingTop: '10px',
        }}
      >
        <FormControl fullWidth margin="normal" required>
          <Stack spacing={2} direction="row">
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => navigate(stationCardDetails.documentationAddress)}
            >
              Learn More
            </Button>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => navigate(stationCardDetails.address)}
            >
              {stationCardDetails.type}
            </Button>
          </Stack>
        </FormControl>
      </Box>
    </Card>
  );
};
