/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ItemCardHeader } from '@backstage/core-components';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@mui/material/FormControl';
import '../card-component.css';

import {
  sendRequestStatus,
  ANVILOPS_REQUEST_STATES,
  Request,
} from '../../../internal';
import { State } from '../../../../../../packages/app/src/store/types';

interface RequestItemProps {
  showRejectionReason: boolean;
  setShowRejectionReason: React.Dispatch<React.SetStateAction<boolean>>;
  handleApproval: (approvalStatus: ANVILOPS_REQUEST_STATES) => Promise<void>;
  isRejectLoading: boolean;
  isApprovalLoading: boolean;
  isResponseReady: boolean;
  rejectionReason: string;
  setRejectionReason: React.Dispatch<React.SetStateAction<string>>;
  rejectionError: boolean;
}

const ApprovalResponse = React.memo(
  ({ approvalResponse }: { approvalResponse: ANVILOPS_REQUEST_STATES }) => {
    if (approvalResponse === ANVILOPS_REQUEST_STATES.APPROVED) {
      return (
        <Alert severity="success">
          <strong>Request Approved!</strong>
        </Alert>
      );
    } else if (approvalResponse === ANVILOPS_REQUEST_STATES.REJECTED) {
      return (
        <Alert severity="error">
          <strong>Request Rejected!</strong>
        </Alert>
      );
    }

    return (
      <Alert severity="warning">
        <strong>Invalid Response!</strong>
      </Alert>
    );
  },
);

const RequestItem: React.FC<RequestItemProps> = React.memo(
  ({
    showRejectionReason,
    setShowRejectionReason,
    handleApproval,
    isRejectLoading,
    isApprovalLoading,
    isResponseReady,
    rejectionReason,
    setRejectionReason,
    rejectionError,
  }) => {
    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (textFieldRef.current && showRejectionReason) {
        textFieldRef.current.focus();
      }
    }, [showRejectionReason]);

    return (
      <FormControl fullWidth margin="normal" required>
        <Stack spacing={2} direction="row">
          {!showRejectionReason && (
            <Button
              size="large"
              fullWidth
              variant="outlined"
              disabled={isRejectLoading && !isResponseReady}
              onClick={() => setShowRejectionReason(true)}
            >
              {isRejectLoading && !isResponseReady ? 'Rejecting…' : 'Reject'}
            </Button>
          )}
          <Button
            size="large"
            fullWidth
            variant="contained"
            disabled={isApprovalLoading && !isResponseReady}
            onClick={() => handleApproval(ANVILOPS_REQUEST_STATES.APPROVED)}
          >
            {isApprovalLoading && !isResponseReady ? 'Approving…' : 'Approve'}
          </Button>
        </Stack>
        {showRejectionReason && (
          <Box mt={2}>
            <TextField
              label="Reason for Rejection"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={rejectionReason}
              onChange={e => setRejectionReason(e.target.value)}
              error={rejectionError}
              helperText={rejectionError ? 'Rejection reason is required.' : ''}
              inputRef={textFieldRef}
            />
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="error"
              disabled={isRejectLoading && !isResponseReady}
              onClick={() => handleApproval(ANVILOPS_REQUEST_STATES.REJECTED)}
              style={{ marginTop: '10px' }}
            >
              {isRejectLoading && !isResponseReady
                ? 'Rejecting…'
                : 'Submit Rejection'}
            </Button>
          </Box>
        )}
      </FormControl>
    );
  },
);

export const ApprovalsCard = ({
  requestObject,
}: {
  requestObject: Request;
}) => {
  const accessToken = useSelector((state: State) => state.accessToken);

  const [approvalResponse, setApprovalResponse] =
    useState<ANVILOPS_REQUEST_STATES>(ANVILOPS_REQUEST_STATES.PENDING);
  const [isApprovalLoading, setIsApprovalLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isResponseReady, setIsResponseReady] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(true);

  const [rejectionReason, setRejectionReason] = useState('');
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [rejectionError, setRejectionError] = useState(false);

  const handleApproval = useCallback(
    async (approvalStatus: ANVILOPS_REQUEST_STATES) => {
      setIsResponseReady(false);

      if (approvalStatus === ANVILOPS_REQUEST_STATES.APPROVED) {
        setIsApprovalLoading(true);
        setIsRejectLoading(false);
      } else if (approvalStatus === ANVILOPS_REQUEST_STATES.REJECTED) {
        if (!rejectionReason.trim()) {
          setRejectionError(true);
          return;
        }
        setIsApprovalLoading(false);
        setIsRejectLoading(true);
        setRejectionError(false);
      }

      const sendRequestResponse = await sendRequestStatus(
        {
          requestId: requestObject.requestId ?? '',
          requestStatus: approvalStatus,
          rejectionReason:
            approvalStatus === ANVILOPS_REQUEST_STATES.REJECTED
              ? rejectionReason
              : undefined,
        },
        accessToken,
      );

      if (
        sendRequestResponse.status === 200 &&
        sendRequestResponse.data.requestStatus === approvalStatus
      ) {
        setApprovalResponse(sendRequestResponse.data.requestStatus);
        setIsResponseReady(true);
      }

      // Hide the card after a certain duration (e.g., 3 seconds)
      setTimeout(() => {
        setIsCardVisible(false);
      }, 3000);
    },
    [accessToken, requestObject.requestId, rejectionReason],
  );

  return (
    <>
      {isCardVisible && (
        <Card key={requestObject.requestId} className="card">
          <CardMedia>
            <ItemCardHeader
              title={requestObject.requestType}
              subtitle={`Request Id: ${requestObject.requestId}`}
            />
          </CardMedia>
          <CardContent
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            <Box height="300px">
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: requestObject.description,
                  }}
                />
              </Typography>
            </Box>
          </CardContent>
          <Divider variant="middle" />
          <Box
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            {!isResponseReady && (
              <RequestItem
                showRejectionReason={showRejectionReason}
                setShowRejectionReason={setShowRejectionReason}
                handleApproval={handleApproval}
                isRejectLoading={isRejectLoading}
                isApprovalLoading={isApprovalLoading}
                isResponseReady={isResponseReady}
                rejectionReason={rejectionReason}
                setRejectionReason={setRejectionReason}
                rejectionError={rejectionError}
              />
            )}
            {/* Display the approval status */}
            {isResponseReady && (
              <ApprovalResponse approvalResponse={approvalResponse} />
            )}
          </Box>
        </Card>
      )}
    </>
  );
};
