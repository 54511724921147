/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { SyntheticEvent } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Tooltip } from '@material-ui/core';

interface BreadBoardAutoCompleteProps {
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: string | null,
  ) => void;
  id: string;
  options: any[];
  value: string;
  label: string;
  isTextFieldDisable?: boolean;
  tooltipText?: string;
}

export const BreadBoardAutoComplete: React.FC<BreadBoardAutoCompleteProps> = ({
  onChange,
  id,
  options,
  value,
  label,
  isTextFieldDisable,
  tooltipText,
}) => {
  return (
    <Tooltip title={tooltipText ?? ''} placement="top-start">
      <Autocomplete
        id={`${id}-auto-complete`}
        disableClearable
        options={options}
        value={value}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            required
            variant="outlined"
            placeholder={label}
            label={label}
            disabled={isTextFieldDisable ?? false}
          />
        )}
      />
    </Tooltip>
  );
};
