/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useState, SyntheticEvent } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Tooltip } from '@material-ui/core';

interface AsynchronousAutoCompleteProps {
  onChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
  id: string;
  label: string;
  value: any;
  options: any[];
  groupBy?: (option: any) => string;
  getOptionLabel: (option: any) => string;
  isTextFieldDisable?: boolean;
  tooltipText?: string;
}

export const AsynchronousAutoComplete: React.FC<
  AsynchronousAutoCompleteProps
> = ({
  onChange,
  id,
  groupBy,
  getOptionLabel,
  options,
  label,
  value,
  isTextFieldDisable,
  tooltipText,
}) => {
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  return (
    <Tooltip title={tooltipText ?? ''} placement="top-start">
      <Autocomplete
        id={`asynchronous-${id}`}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableClearable
        value={value}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        groupBy={groupBy}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={label}
            required
            disabled={isTextFieldDisable ?? false}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Tooltip>
  );
};
