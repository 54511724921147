/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';

import {
  getRequestStatus,
  ANVILOPS_REQUEST_STATES,
  Request,
  BUTTON_COLOR,
  ANVILOPS_REQUEST_STATES_LABEL,
  ANVILOPS_REQUEST_STEPS_NUMBER,
} from '../../../internal';
import { State } from '../../../../../../packages/app/src/store/types';
import { FETCH_STATUS_DELAY_TIME } from '../../../../../../packages/app/src/common/constants';

interface RequestStatusChipProps {
  requestDetails: Partial<Request>;
  setActiveStep: (activeStep: number) => void;
}

export const RequestStatusChip: React.FC<RequestStatusChipProps> = ({
  requestDetails,
  setActiveStep,
}) => {
  const accessToken = useSelector((state: State) => state.accessToken);
  const [requestStatus, setRequestStatus] = useState<string>(
    requestDetails.requestStatus ?? ANVILOPS_REQUEST_STATES.PENDING,
  );

  const failedConditionForRequestStatus =
    requestStatus === ANVILOPS_REQUEST_STATES.WORKFLOW_FAILED ||
    requestStatus === ANVILOPS_REQUEST_STATES.REQUEST_FAILED ||
    requestStatus === ANVILOPS_REQUEST_STATES.REQUEST_QUEUEING_FAILED ||
    requestStatus === ANVILOPS_REQUEST_STATES.ACCOUNT_CUSTOMIZATION_FAILED ||
    requestStatus === ANVILOPS_REQUEST_STATES.ACCOUNT_EMAIL_NOT_AVAILABLE ||
    requestStatus === ANVILOPS_REQUEST_STATES.WORKFLOW_TRIGGER_FAILED;

  const stoppingConditionForRequestStatus =
    requestStatus === ANVILOPS_REQUEST_STATES.COMPLETED ||
    requestStatus === ANVILOPS_REQUEST_STATES.REJECTED ||
    failedConditionForRequestStatus;

  // Function to make the API request
  const fetchRequestStatus = useCallback(async () => {
    try {
      if (requestDetails.requestId) {
        const getRequestStatusResponse = await getRequestStatus(
          requestDetails.requestId,
          accessToken,
        );
        setRequestStatus(getRequestStatusResponse.data);
      }
      if (stoppingConditionForRequestStatus) {
        return;
      }
      setTimeout(() => fetchRequestStatus(), FETCH_STATUS_DELAY_TIME); // Retry after FETCH_STATUS_DELAY_TIME (adjust as needed)
    } catch (error) {
      console.error('Error fetching approval status:', error);
    }
  }, [
    accessToken,
    requestDetails.requestId,
    stoppingConditionForRequestStatus,
  ]);

  useEffect(() => {
    if (!stoppingConditionForRequestStatus) {
      fetchRequestStatus();
    }
  }, [fetchRequestStatus, stoppingConditionForRequestStatus]);

  let color: BUTTON_COLOR;
  let statusLabel: string;
  if (requestStatus === ANVILOPS_REQUEST_STATES.REJECTED) {
    color = BUTTON_COLOR.ERROR;
    statusLabel = ANVILOPS_REQUEST_STATES_LABEL.REJECTED;
    setActiveStep(ANVILOPS_REQUEST_STEPS_NUMBER.APPROVED_REJECTED);
  } else if (requestStatus === ANVILOPS_REQUEST_STATES.PENDING) {
    color = BUTTON_COLOR.WARNING;
    statusLabel = ANVILOPS_REQUEST_STATES_LABEL.WAITING_APPROVAL;
    setActiveStep(ANVILOPS_REQUEST_STEPS_NUMBER.WAITING_APPROVAL);
  } else if (requestStatus === ANVILOPS_REQUEST_STATES.APPROVED) {
    color = BUTTON_COLOR.SUCCESS;
    statusLabel = ANVILOPS_REQUEST_STATES_LABEL.APPROVED;
    setActiveStep(ANVILOPS_REQUEST_STEPS_NUMBER.APPROVED_REJECTED);
  } else if (requestStatus === ANVILOPS_REQUEST_STATES.COMPLETED) {
    color = BUTTON_COLOR.SECONDARY;
    statusLabel = ANVILOPS_REQUEST_STATES_LABEL.COMPLETED;
    setActiveStep(ANVILOPS_REQUEST_STEPS_NUMBER.COMPLETED_FAILED);
  } else if (failedConditionForRequestStatus) {
    color = BUTTON_COLOR.ERROR;
    statusLabel = ANVILOPS_REQUEST_STATES_LABEL.FAILED;
    setActiveStep(ANVILOPS_REQUEST_STEPS_NUMBER.COMPLETED_FAILED);
  } else {
    color = BUTTON_COLOR.INFO;
    statusLabel = ANVILOPS_REQUEST_STATES_LABEL.IN_PROGRESS;
    setActiveStep(ANVILOPS_REQUEST_STEPS_NUMBER.IN_PROGRESS);
  }
  return (
    <Button style={{ width: '162px' }} variant="outlined" color={color}>
      {statusLabel}
    </Button>
  );
};
