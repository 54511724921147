/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ItemCardGrid } from '@backstage/core-components';

import Alert from '@mui/material/Alert';

import { State } from '../../../../../../packages/app/src/store/types';

import { Request, REQUEST_TYPE, getPendingRequests } from '../../../internal';
import { ApprovalsCard } from './ApprovalsCard';

export const ApprovalsPage = () => {
  const { requestId: urlRequestId } = useParams(); // Get the request ID from the URL

  const accessToken = useSelector((state: State) => state.accessToken);
  const email = useSelector((state: State) => state.email);

  const [approverRequests, setApproverRequests] = useState<Request[]>([]);

  useEffect(() => {
    async function getApproverRequests() {
      if (accessToken) {
        const pendingRequestsResponse = await getPendingRequests(
          email,
          REQUEST_TYPE.APPROVER,
          accessToken,
        );

        if (urlRequestId !== undefined) {
          const filteredRequests = pendingRequestsResponse.data.filter(
            (item: Request) => item.requestId === urlRequestId,
          );
          setApproverRequests(filteredRequests);
        }
        setApproverRequests(pendingRequestsResponse.data);
      }
    }

    getApproverRequests();
  }, [accessToken, email, urlRequestId]);

  if (approverRequests.length === 0) {
    return (
      <Alert severity="info">
        <strong>No Pending Approvals Available!</strong>
      </Alert>
    );
  }

  return (
    <ItemCardGrid>
      {approverRequests.map((request: Request) => (
        <ApprovalsCard requestObject={request} />
      ))}
    </ItemCardGrid>
  );
};
