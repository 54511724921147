/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable @backstage/no-forbidden-package-imports */
import React, { useEffect } from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSSOPermissionSetsUsers } from '@internal/plugin-self-service/src/internal';
import { setSSOPermissionSetsUsers } from '../../../../../packages/app/src/store/actions';
import {
  SSOPermissionSetsUser,
  State,
} from '../../../../../packages/app/src/store/types';

export const DenseTable = ({ data }: { data: SSOPermissionSetsUser[] }) => {
  const columns: TableColumn[] = [
    {
      title: 'Email Id',
      field: 'emailId',
      highlight: true,
    },
    {
      title: 'Permission Sets',
      field: 'permissionSets',
      highlight: true,
    },
  ];

  const userData: any[] = data.map(user => {
    const permissionSetsData =
      Object.keys(user.permissionSets).length !== 0
        ? Object.entries(user.permissionSets).map(
            ([permissionSetName, permissions]) => (
              <>
                <li key={permissionSetName}>
                  {permissionSetName}: {permissions.join(', ')}
                </li>
                <br />
              </>
            ),
          )
        : 'No permission sets assigned yet to this account.';

    return {
      emailId: user.emailId,
      permissionSets: permissionSetsData,
    };
  });

  return (
    <Table
      title={`Registered SSO Users (${userData.length})`}
      options={{ search: true, paging: true, pageSize: 10 }}
      columns={columns}
      data={userData}
    />
  );
};

export const UsersTable = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector((state: State) => state.accessToken);

  useEffect(() => {
    if (accessToken) {
      fetchSSOPermissionSetsUsers(accessToken).then(response => {
        dispatch(setSSOPermissionSetsUsers(response));
      });
    }
  }, [dispatch, accessToken]);

  const ssoPermissionSetsUsers = useSelector(
    (state: State) => state.ssoPermissionSetsUsers,
  );

  return <DenseTable data={ssoPermissionSetsUsers} />;
};
