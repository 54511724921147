export enum EMAIL_TYPE {
  APPLICATION = 'application',
  DEVELOPER = 'developer',
}

export interface CreateEmailEntryRequest {
  emails: string[];
  accountType: EMAIL_TYPE;
}

export interface CreateUserEntryRequest {
  emails: string[];
}
