import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { Environments } from '../../../internal';

interface EnvironmentOptionsProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label: string;
  environments: Environments;
}

export const EnvironmentOptions: React.FC<EnvironmentOptionsProps> = ({
  onChange,
  value,
  label,
  environments,
}) => {
  return (
    <>
      <FormLabel id="env-radio-button">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="env-radio-button"
        name="radio-buttons-group"
        onChange={onChange}
        value={value}
      >
        {environments.Option1.isVisible && (
          <FormControlLabel
            value={environments.Option1.value}
            control={<Radio />}
            label={environments.Option1.label}
          />
        )}
        {environments.Option2.isVisible && (
          <FormControlLabel
            value={environments.Option2.value}
            control={<Radio />}
            label={environments.Option2.label}
          />
        )}
        {environments.Option3.isVisible && (
          <FormControlLabel
            value={environments.Option3.value}
            control={<Radio />}
            label={environments.Option3.label}
          />
        )}
        {environments.Option4.isVisible && (
          <FormControlLabel
            value={environments.Option4.value}
            control={<Radio />}
            label={environments.Option4.label}
          />
        )}
        {environments.Option5.isVisible && (
          <FormControlLabel
            value={environments.Option5.value}
            control={<Radio />}
            label={environments.Option5.label}
          />
        )}
      </RadioGroup>
    </>
  );
};
