/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider, FormControl } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

interface BreadBoardButtonGroupProps {
  handleSubmit: () => void;
  submitCheck: () => boolean;
  handleReset: () => void;
  isLoading: boolean;
}

export const BreadBoardButtonGroup: React.FC<BreadBoardButtonGroupProps> = ({
  handleSubmit,
  submitCheck,
  handleReset,
  isLoading,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <br />
      <Divider />
      <FormControl fullWidth margin="normal" required>
        <Stack spacing={3} direction="row">
          <Button
            fullWidth
            variant="outlined"
            onClick={() => navigate('/self-service')}
          >
            Back
          </Button>
          <Button fullWidth variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button
            fullWidth
            variant={submitCheck() ? 'outlined' : 'contained'}
            disabled={submitCheck()}
            onClick={handleSubmit}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Stack>
      </FormControl>
    </>
  );
};
