/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EntityPeekAheadPopover } from '@backstage/plugin-catalog-react';
import './label.css';
import { State } from '../../../../../../packages/app/src/store/types';

export const UserHeaderLabel = () => {
  const [greeting, setGreeting] = useState<string>('');
  const [entity, setEntity] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');

  const email = useSelector((state: State) => state.email);

  useEffect(() => {
    if (email) {
      setEntity(email.split('@')[0]);

      if (entity) {
        if (entity.includes('-')) {
          setFirstName(
            `${entity.split('-')[0].charAt(0).toUpperCase()}${entity
              .split('-')[0]
              .slice(1)} ${entity.split('-')[1].charAt(0).toUpperCase()}${entity
              .split('-')[1]
              .slice(1)}`,
          );
        } else {
          setFirstName(
            `${entity.split('.')[0].charAt(0).toUpperCase()}${entity
              .split('.')[0]
              .slice(1)}`,
          );
        }
      }
    }
  }, [email, entity]);

  useEffect(() => {
    const currentTime = new Date().getHours();

    if (currentTime >= 5 && currentTime < 12) {
      setGreeting('Good morning');
    } else if (currentTime >= 12 && currentTime < 18) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }
  }, []);

  const args = { entityRef: `user:default/${entity}` };

  return (
    <EntityPeekAheadPopover {...args}>
      <div className="highlighted-container">
        <strong>{`${greeting}, ${firstName}!`}</strong>
      </div>
    </EntityPeekAheadPopover>
  );
};
