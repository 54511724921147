import React from 'react';

const LogoFull = () => {
  // const classes = useStyles();

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="192.000000pt"
      height="192.000000pt"
      viewBox="0 0 2000.000000 1.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,250.000000) scale(0.0400000,-0.0400000)"
        fill="#FFFFFF"
        stroke="none"
      >
        <path
          d="M7397 13463 c-4 -3 -7 -786 -7 -1740 l0 -1733 215 0 215 0 0 1525 0
    1525 5795 0 5795 0 -2 -5792 -3 -5793 -5792 -3 -5793 -2 -2 1527 -3 1528 -210
    0 -210 0 -3 -1738 -2 -1737 6220 0 6220 0 -2 6218 -3 6217 -6211 3 c-3416 1
    -6214 -1 -6217 -5z"
        />
        <path
          d="M540 7265 l0 -2345 808 0 c815 0 901 3 1142 40 90 14 260 59 390 105
    450 157 824 472 1083 910 229 389 337 813 337 1329 0 858 -359 1528 -1055
    1967 -270 170 -600 278 -994 324 -101 12 -282 15 -918 15 l-793 0 0 -2345z
    m1608 1834 c443 -48 779 -209 1069 -512 374 -393 538 -885 494 -1482 -28 -371
    -122 -675 -293 -949 -166 -265 -348 -435 -598 -561 -128 -65 -202 -91 -350
    -127 -211 -50 -322 -58 -867 -58 l-493 0 0 1850 0 1850 470 0 c263 0 514 -5
    568 -11z"
        />
        <path
          d="M12141 9588 c7 -13 327 -535 711 -1161 384 -626 698 -1140 698 -1142
    0 -2 -232 -367 -516 -811 -738 -1155 -984 -1542 -984 -1549 0 -3 149 -4 332
    -3 l332 3 576 929 c317 512 581 931 587 933 6 2 284 -417 619 -932 l608 -935
    328 0 c180 0 328 2 328 4 0 3 -349 530 -777 1173 -427 642 -778 1175 -780
    1183 -2 8 309 528 693 1155 383 627 700 1148 705 1158 9 16 -9 17 -323 17
    l-333 0 -535 -903 c-295 -496 -539 -902 -543 -902 -5 0 -249 406 -544 903
    l-535 902 -329 0 -330 0 12 -22z"
        />
        <path
          d="M6271 8479 c-616 -74 -1104 -512 -1276 -1144 -115 -424 -106 -970 22
    -1339 209 -605 735 -1020 1388 -1095 230 -26 543 -20 735 15 186 33 382 94
    509 158 l51 26 0 256 0 256 -52 -32 c-238 -141 -533 -210 -898 -210 -549 1
    -941 215 -1148 627 -51 101 -122 350 -122 428 l0 25 1203 0 1203 0 13 78 c20
    115 26 442 11 575 -63 579 -274 956 -671 1197 -128 78 -326 147 -485 170 -111
    16 -383 21 -483 9z m384 -484 c166 -27 298 -87 414 -188 201 -175 301 -430
    301 -769 l0 -128 -946 0 -947 0 7 73 c15 145 72 347 134 472 78 157 191 293
    325 388 197 140 451 195 712 152z"
        />
        <path
          d="M8430 8443 c0 -5 275 -799 612 -1765 l613 -1758 340 0 341 0 623
    1748 c343 961 626 1755 628 1765 5 16 -13 17 -275 17 -181 0 -283 -4 -287 -10
    -4 -6 -112 -322 -240 -703 -128 -380 -296 -875 -374 -1099 -168 -486 -353
    -1055 -382 -1176 -12 -48 -25 -90 -28 -94 -4 -4 -114 329 -245 740 -228 713
    -752 2318 -762 2334 -7 10 -564 10 -564 1z"
        />
      </g>
    </svg>
  );
};

export default LogoFull;
