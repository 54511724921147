/* eslint-disable @backstage/no-relative-monorepo-imports */
import axios from 'axios';
import {
  BACKEND_URL,
  ACCOUNTS_API_ENDPOINT,
  EMAIL_ENDPOINT,
  USERS_API_ENDPOINT,
} from '../../../../packages/app/src/common/constants';
import { CreateEmailEntryRequest, CreateUserEntryRequest } from './types';

export const createEmailEntry = async (
  createEmailEntryRequest: CreateEmailEntryRequest,
  accessToken: string,
) => {
  const response = await axios.post(
    `${BACKEND_URL}/${ACCOUNTS_API_ENDPOINT}/${EMAIL_ENDPOINT}`,
    createEmailEntryRequest,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.status;
};

export const createUserEntry = async (
  createUserEntryRequest: CreateUserEntryRequest,
  accessToken: string,
) => {
  const response = await axios.post(
    `${BACKEND_URL}/${USERS_API_ENDPOINT}`,
    createUserEntryRequest,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.status;
};
