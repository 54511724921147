/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import HomePageImage from './devxImages/Dev-X-Landing-Page.png';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { UserHeaderLabel } from '../common';

export const HomePage = () => {
  return (
    <Page themeId="service">
      <Header title="AnvilOps" subtitle="Where we value Developer Experience">
        <UserHeaderLabel />
      </Header>
      <Content noPadding>
        <Grid container>
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
        </Grid>

        <Card
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <CardMedia component="img" src={HomePageImage} />
        </Card>
      </Content>
    </Page>
  );
};
