/* eslint-disable no-console */
import config from '../config/default.json';
import { STAGE } from './environment';

export const BREADBOARD_ADMIN_MAIL = config.backstage.breadboardAdminMail;
export const SSO_PERMISSION_SETS = config.backstage.ssoPermissionSets;
export const PERMISSION_CHANGE_TYPES = config.backstage.permissionChangeTypes;
export const ROUTE53_DOMAINS = config.backstage.route53Domains;
export const SUPPORT_BUTTON_REDIRECT_LINK =
  config.backstage.supportButtonRedirectLink;

export const SET_EMAIL = 'SET_EMAIL';
export const SET_AVAILABLE_DEVELOPER_ACCOUNTS =
  'SET_AVAILABLE_DEVELOPER_ACCOUNTS';
export const SET_AVAILABLE_APPLICATION_ACCOUNTS =
  'SET_AVAILABLE_APPLICATION_ACCOUNTS';
export const SET_APPLICATION_ACCOUNTS = 'SET_APPLICATION_ACCOUNTS';
export const SET_GITHUB_REPOS = 'SET_GITHUB_REPOS';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_LOGGED_IN_TIME_TOKEN = 'SET_LOGGED_IN_TIME_TOKEN';
export const SET_SSO_PERMISSION_SETS_USERS_ACTION =
  'SET_SSO_PERMISSION_SETS_USERS_ACTION';
export const SET_CUSTOM_SCOPES = 'SET_CUSTOM_SCOPES';
export const SET_APP_CLIENTS = 'SET_APP_CLIENTS';
export const SET_APP_CLIENT_DETAILS = 'SET_APP_CLIENT_DETAILS';

export const ACCOUNTS_API_ENDPOINT = 'accounts';
export const USERS_API_ENDPOINT = 'users';
export const REQUEST_ENDPOINT = 'request';
export const EMAIL_ENDPOINT = 'email';
export const GITHUB_REPOS_API_ENDPOINT = 'github/repos';
export const FETCH_ACCOUNT_EMAIL_API_ENDPOINT = 'fetch-account-email';
export const COGNITO_API_ENDPOINT = 'cognito';
export const COGNITO_CREATE_APP_CLIENT_ENDPOINT = 'create-app-client';
export const COGNITO_LIST_RESOURCE_SERVER_ENDPOINT = 'list-resource-server';
export const COGNITO_LIST_APP_CLIENTS_ENDPOINT = 'list-app-clients';
export const COGNITO_CREATE_RESOURCE_SERVER_ENDPOINT = 'create-resource-server';
export const COGNITO_DESCRIBE_APP_CLIENT_ENDPOINT = 'describe-app-client';
export const COGNITO_UPDATE_APP_CLIENT_ENDPOINT = 'update-app-client';

export const BACKEND_URL = config.backstage[STAGE].backendUrl;
export const DEFAULT_APPROVER = config.backstage[STAGE].defaultApprover;
export const APPROVER_EMAILS = config.backstage[STAGE].approverEmails;
export const COGNITO_APPROVER_EMAILS =
  config.backstage[STAGE].cognitoApproverEmails;
export const REDIRECT_DOMAIN = config.backstage[STAGE].redirectDomain;
export const ADMIN_EMAILS = config.backstage[STAGE].adminEmails;

export const DEVELOPER_ACCOUNTS_OU = config.backstage.developerAccountsOU;
export const PROD_APPLICATION_ACCOUNTS_OU =
  config.backstage.prodApplicationAccountsOU;
export const NON_PROD_APPLICATION_ACCOUNTS_OU =
  config.backstage.nonProdApplicationAccountsOU;
export const USER_GROUPS_FOR_ACCOUNT_CREATION =
  config.backstage.userGroupsForAccountCreation;

export const AWS_USER_ACCESS_WORKFLOW_GITHUB_REPO =
  config.backstage.awsUserAccessWorkflowRepo;
export const AWS_USER_ACCESS_WORKFLOW_GITHUB_BASE_BRANCH =
  config.backstage.awsUserAccessWorkflowBranch;
export const AWS_USER_ACCESS_WORKFLOW_GITHUB_FILE_PATH =
  config.backstage.awsUserAccessWorkflowFile;

export const SUB_DOMAIN_CREATION_WORKFLOW_GITHUB_REPO =
  config.backstage.createDomainWorkflowRepo;
export const SUB_DOMAIN_CREATION_WORKFLOW_GITHUB_BASE_BRANCH =
  config.backstage.createDomainWorkflowBranch;
export const SUB_DOMAIN_CREATION_WORKFLOW_GITHUB_FILE_PATH =
  config.backstage.createDomainWorkflowFile;

export const DEPLOY_ROLE_WORKFLOW_GITHUB_REPO =
  config.backstage.deployRoleWorkflowRepo;
export const DEPLOY_ROLE_WORKFLOW_GITHUB_BASE_BRANCH =
  config.backstage.deployRoleWorkflowBranch;
export const DEPLOY_ROLE_WORKFLOW_GITHUB_FILE_PATH =
  config.backstage.deployRoleWorkflowFile;

export const AWS_ACCOUNT_CREATION_WORKFLOW_GITHUB_REPO =
  config.backstage.accountCreationWorkflowRepo;
export const AWS_ACCOUNT_CREATION_WORKFLOW_GITHUB_BASE_BRANCH =
  config.backstage.accountCreationWorkflowBranch;
export const AWS_ACCOUNT_CREATION_WORKFLOW_GITHUB_FILE_PATH =
  config.backstage.accountCreationWorkflowFile;

export const APP_CLIENT_CREATION_FORM_NAME =
  config.backstage.appClientCreationFormName;
export const COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME =
  config.backstage.cognitoResourceServerCreationFormName;
export const APP_CLIENT_MODIFICATION_FORM_NAME =
  config.backstage.appClientModificationFormName;
export const DEPLOY_ROLE_FORM_NAME =
  config.backstage.deployRoleCreationFormName;
export const SUB_DOMAIN_CREATION_FORM_NAME =
  config.backstage.domainCreationFormName;
export const AWS_DEV_ACCOUNT_CREATION_FORM_NAME =
  config.backstage.awsDevAccountCreationFormName;
export const AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME =
  config.backstage.awsWorkloadAccountCreationFormName;
export const AWS_USER_ACCESS_FORM_NAME = config.backstage.awsUserAccessFormName;
export const ONBOARD_REPOSIOTRY_FORM_NAME =
  config.backstage.onboardRepositoryFormName;
export const REGISTER_AVAILABLE_EMAIL_FORM =
  config.backstage.registerAvailableEmailForm;
export const REGISTER_NEW_SSO_USER_FORM = config.backstage.registerSSOUserForm;
export const USERS_TABLE_TITLE = config.backstage.usersTableTitle;

export const ONBOARD_REPOSIOTRY_FORM_DESCRIPTION =
  config.backstage.onboardRepositoryFormDescription;
export const APP_CLIENT_CREATION_FORM_DESCRIPTION =
  config.backstage.appClientCreationFormDescription;
export const COGNITO_RESOURCE_SERVER_CREATION_FORM_DESCRIPTION =
  config.backstage.cognitoResourceServerCreationFormDescription;
export const APP_CLIENT_MODIFICATION_FORM_DESCRIPTION =
  config.backstage.appClientModificationFormDescription;
export const DEPLOY_ROLE_FORM_DESCRIPTION =
  config.backstage.deployRoleCreationFormDescription;
export const SUB_DOMAIN_CREATION_FORM_DESCRIPTION =
  config.backstage.domainCreationFormDescription;
export const AWS_WORKLOAD_ACCOUNT_CREATION_FORM_DESCRIPTION =
  config.backstage.awsWorkloadAccountCreationFormDescription;
export const AWS_DEV_ACCOUNT_CREATION_FORM_DESCRIPTION =
  config.backstage.awsDevAccountCreationFormDescription;
export const AWS_USER_ACCESS_FORM_DESCRIPTION =
  config.backstage.awsUserAccessFormDescription;
export const REGISTER_AVAILABLE_EMAIL_DESCRIPTION =
  config.backstage.registerAvailableEmailDescription;
export const REGISTER_NEW_SSO_USER_DESCRIPTION =
  config.backstage.registerSSOUserDescription;
export const USERS_TABLE_DESCRIPTION = config.backstage.usersTableDescription;

export const MAIL_RESPONSE = `you will receive a mail from '${BREADBOARD_ADMIN_MAIL}' when request is fullfilled!!`;
export const DEPLOY_ROLE_SUCCESS_RESPONSE = `Deploy role creation request approved, ${MAIL_RESPONSE}`;
export const SUB_DOMAIN_CREATION_SUCCESS_RESPONSE = `Domain name creation request approved, ${MAIL_RESPONSE}`;
export const AWS_WORKLOAD_ACCOUNT_CREATION_SUCCESS_RESPONSE = `Request for new application AWS account creation approved, ${MAIL_RESPONSE}`;
export const AWS_DEV_ACCOUNT_CREATION_SUCCESS_RESPONSE = `Request for new individual developer AWS account creation approved, ${MAIL_RESPONSE}`;
export const AWS_USER_ACCESS_SUCCESS_RESPONSE = `SSO permission set change request approved, ${MAIL_RESPONSE}`;
export const REJECTED_RESPONSE = 'Request rejected from approver!!';
export const APPROVED_RESPONSE = 'Request approved from approver!!';
export const FAILED_RESPONSE =
  'Request failed, contact AnvilOps Team for the response!!';
export const COMPLETED_RESPONSE = `Request completed processing, check mail from the '${BREADBOARD_ADMIN_MAIL}'`;
export const FETCH_STATUS_DELAY_TIME = 60000;
export const AWS_DEV_ACCOUNT_CREATION_INPUTS_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/developer-account-creation/#inputs';
export const AWS_APPLICATION_ACCOUNT_CREATION_INPUTS_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/application-account-creation/#inputs';
export const AWS_USER_ACCESS_MODIFICATION_INPUTS_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/user-access-modification/#inputs';
export const AWS_DEV_ACCOUNT_CREATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/developer-account-creation';
export const AWS_APPLICATION_ACCOUNT_CREATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/application-account-creation';
export const AWS_USER_ACCESS_MODIFICATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/user-access-modification';
export const SUB_DOMAIN_CREATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/sub-domain-creation';
export const DEPLOY_ROLE_CREATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/deploy-role-creation';
export const COGNITO_APP_CLIENT_CREATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/cognito-app-client-creation';
export const COGNITO_APP_CLIENT_MODIFICATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/cognito-app-client-modification';
export const COGNITO_RESOURCE_SERVER_CREATION_DOC_LINK =
  '/docs/default/component/ssg-anvilops-user-manual/cognito-app-client-modification';

export const ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MIN_LENGTH =
  config.backstage.accountNameForAccountCreationMinLength;
export const ACCOUNT_NAME_FOR_ACCOUNT_CREATION_MAX_LENGTH =
  config.backstage.accountNameForAccountCreationMaxLength;

export const APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH =
  config.backstage.applicationNameForAppClientMinLength;
export const APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH =
  config.backstage.applicationNameForAppClientMaxLength;

export const PROJECT_NAME_FOR_DEPLOY_ROLE_MIN_LENGTH =
  config.backstage.projectNameForDeployRoleMinLength;
export const PROJECT_NAME_FOR_DEPLOY_ROLE_MAX_LENGTH =
  config.backstage.projectNameForDeployRoleMaxLength;

export const SUB_DOMAIN_NAME_MIN_LENGTH =
  config.backstage.subDomainNameMinLength;
export const SUB_DOMAIN_NAME_MAX_LENGTH =
  config.backstage.subDomainNameMaxLength;
