/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { ChangeEvent } from 'react';
import {
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { State } from '../../../../../../packages/app/src/store/types';

interface BreadBoardRequesterProps {
  requesterValue: string;
  onRequesterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  requestedByValue: string;
  onRequestedByChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
}

export const BreadBoardRequester: React.FC<BreadBoardRequesterProps> = ({
  requesterValue,
  onRequesterChange,
  requestedByValue,
  onRequestedByChange,
  error,
  helperText,
}) => {
  const email = useSelector((state: State) => state.email);

  return (
    <>
      <FormLabel id="requester-label">Requester</FormLabel>
      <RadioGroup
        aria-labelledby="env-radio-button"
        name="radio-buttons-group"
        onChange={onRequesterChange}
        value={requesterValue}
      >
        <Stack spacing={10} direction="row">
          <FormControlLabel value="self" control={<Radio />} label="Self" />
          <FormControlLabel
            value="another"
            control={<Radio />}
            label="Another User"
          />
        </Stack>
      </RadioGroup>
      <br />

      <TextField
        label="Requested by"
        placeholder="Requested by"
        disabled={requesterValue === 'self'}
        variant="outlined"
        onChange={onRequestedByChange}
        value={requesterValue === 'self' ? email : requestedByValue}
        required
        error={error}
        helperText={helperText}
      />
    </>
  );
};
