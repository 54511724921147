/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';

import { ItemCardGrid } from '@backstage/core-components';

import {
  APP_CLIENT_CREATION_FORM_NAME,
  COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME,
  APP_CLIENT_MODIFICATION_FORM_NAME,
  AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME,
  AWS_DEV_ACCOUNT_CREATION_FORM_NAME,
  AWS_USER_ACCESS_FORM_NAME,
  SUB_DOMAIN_CREATION_FORM_NAME,
  DEPLOY_ROLE_FORM_NAME,
  APP_CLIENT_CREATION_FORM_DESCRIPTION,
  COGNITO_RESOURCE_SERVER_CREATION_FORM_DESCRIPTION,
  APP_CLIENT_MODIFICATION_FORM_DESCRIPTION,
  AWS_WORKLOAD_ACCOUNT_CREATION_FORM_DESCRIPTION,
  AWS_DEV_ACCOUNT_CREATION_FORM_DESCRIPTION,
  AWS_USER_ACCESS_FORM_DESCRIPTION,
  SUB_DOMAIN_CREATION_FORM_DESCRIPTION,
  DEPLOY_ROLE_FORM_DESCRIPTION,
  ONBOARD_REPOSIOTRY_FORM_NAME,
  ONBOARD_REPOSIOTRY_FORM_DESCRIPTION,
  DEPLOY_ROLE_CREATION_DOC_LINK,
  AWS_USER_ACCESS_MODIFICATION_DOC_LINK,
  SUB_DOMAIN_CREATION_DOC_LINK,
  COGNITO_RESOURCE_SERVER_CREATION_DOC_LINK,
  COGNITO_APP_CLIENT_MODIFICATION_DOC_LINK,
  COGNITO_APP_CLIENT_CREATION_DOC_LINK,
  AWS_DEV_ACCOUNT_CREATION_DOC_LINK,
  AWS_APPLICATION_ACCOUNT_CREATION_DOC_LINK,
} from '../../../../../../packages/app/src/common/constants';

import { StationCard } from './StationCard';
import { StationCardDetails } from '../../../internal';

export const StationPage = () => {
  const selfServiceStations: StationCardDetails[] = [
    {
      title: ONBOARD_REPOSIOTRY_FORM_NAME,
      cardId: '1',
      address: '/catalog-import',
      description: ONBOARD_REPOSIOTRY_FORM_DESCRIPTION,
      type: 'Register',
      documentationAddress: '/catalog-import',
    },
    {
      title: AWS_WORKLOAD_ACCOUNT_CREATION_FORM_NAME,
      cardId: '2',
      address: '/aws-workload-account-request',
      description: AWS_WORKLOAD_ACCOUNT_CREATION_FORM_DESCRIPTION,
      type: 'Create',
      documentationAddress: AWS_APPLICATION_ACCOUNT_CREATION_DOC_LINK,
    },
    {
      title: AWS_DEV_ACCOUNT_CREATION_FORM_NAME,
      cardId: '3',
      address: '/aws-dev-account-request',
      description: AWS_DEV_ACCOUNT_CREATION_FORM_DESCRIPTION,
      type: 'Create',
      documentationAddress: AWS_DEV_ACCOUNT_CREATION_DOC_LINK,
    },
    {
      title: APP_CLIENT_CREATION_FORM_NAME,
      cardId: '4',
      address: '/central-identity-app',
      description: APP_CLIENT_CREATION_FORM_DESCRIPTION,
      type: 'Request',
      documentationAddress: COGNITO_APP_CLIENT_CREATION_DOC_LINK,
    },
    {
      title: APP_CLIENT_MODIFICATION_FORM_NAME,
      cardId: '5',
      address: '/cognito-app-client-modification',
      description: APP_CLIENT_MODIFICATION_FORM_DESCRIPTION,
      type: 'Request',
      documentationAddress: COGNITO_APP_CLIENT_MODIFICATION_DOC_LINK,
    },
    {
      title: COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME,
      cardId: '6',
      address: '/cognito-resource-server-create',
      description: COGNITO_RESOURCE_SERVER_CREATION_FORM_DESCRIPTION,
      type: 'Request',
      documentationAddress: COGNITO_RESOURCE_SERVER_CREATION_DOC_LINK,
    },
    {
      title: AWS_USER_ACCESS_FORM_NAME,
      cardId: '7',
      address: '/aws-user-access',
      description: AWS_USER_ACCESS_FORM_DESCRIPTION,
      type: 'Execute',
      documentationAddress: AWS_USER_ACCESS_MODIFICATION_DOC_LINK,
    },
    {
      title: SUB_DOMAIN_CREATION_FORM_NAME,
      cardId: '8',
      address: '/domain',
      description: SUB_DOMAIN_CREATION_FORM_DESCRIPTION,
      type: 'Create',
      documentationAddress: SUB_DOMAIN_CREATION_DOC_LINK,
    },
    {
      title: DEPLOY_ROLE_FORM_NAME,
      cardId: '9',
      address: '/aws-deploy-role',
      description: DEPLOY_ROLE_FORM_DESCRIPTION,
      type: 'Create',
      documentationAddress: DEPLOY_ROLE_CREATION_DOC_LINK,
    },
  ];

  return (
    <ItemCardGrid>
      {selfServiceStations.map(
        (stationCardObject: StationCardDetails, index: number) => (
          <StationCard stationCardDetails={stationCardObject} key={index} />
        ),
      )}
    </ItemCardGrid>
  );
};
