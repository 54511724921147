import { TableColumn, Table } from '@backstage/core-components';
import { Grid, Link } from '@material-ui/core';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { EntityLink } from '@backstage/catalog-model';

interface ItemLink {
  items: EntityLink[];
}

export const DenseTable = ({ items }: ItemLink) => {
  const columns: TableColumn[] = [
    { title: 'Title', field: 'title' },
    { title: 'Url', field: 'url' },
  ];

  const data = items.map(item => {
    return {
      title: item.title,
      url: (
        <Link href={item.url} target="_blank" rel="noopener">
          {item.url}
        </Link>
      ),
    };
  });

  return (
    <Table
      title="Links"
      options={{ search: false, paging: false }}
      columns={columns}
      data={data}
    />
  );
};

export const GroupLinksCard = () => {
  const { entity } = useEntity();
  const links = (entity.metadata.links || []) as EntityLink[];

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <DenseTable items={links} />
      </Grid>
    </Grid>
  );
};
