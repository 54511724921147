import { Store, NOTIFICATION_TYPE } from 'react-notifications-component';

export const addNotifications = (
  title: string,
  message: string,
  type: NOTIFICATION_TYPE,
) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    container: 'bottom-right',
    dismiss: {
      duration: 20000,
      showIcon: true,
      click: true,
      pauseOnHover: true,
    },
  });
};
