/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useEffect, useState } from 'react';
import { Content, Header, InfoCard, Page } from '@backstage/core-components';

import { FormHelperText, FormControl, Grid, Checkbox } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import {
  APP_CLIENT_MODIFICATION_FORM_NAME,
  APP_CLIENT_MODIFICATION_FORM_DESCRIPTION,
  COGNITO_APPROVER_EMAILS,
} from '../../../../../packages/app/src/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../../packages/app/src/store/types';
import {
  AsynchronousAutoComplete,
  BreadBoardAutoComplete,
  BreadBoardButtonGroup,
} from '../common';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  triggerRequest,
  getRequestId,
  fetchCognitoClientDetails,
  fetchCognitoUserPoolClients,
  fetchCustomScopesList,
  validateURL,
  CognitoAppClientDetails,
  SDLC_STAGES,
  CognitoApplicationClientUpdateRequest,
  formatStringArray,
} from '../../internal';

import {
  setAppClientDetails,
  setAppClients,
  setCustomScopes,
} from '../../../../../packages/app/src/store/actions';
import Autocomplete from '@mui/material/Autocomplete';

export const CognitoAppClientModificationForm = () => {
  const dispatch = useDispatch();
  const email = useSelector((state: State) => state.email);
  const accessToken = useSelector((state: State) => state.accessToken);

  const [callbackUrlsValue, setCallbackUrlsValue] = useState<string[]>(['']);
  const [callbackUrlValidations, setCallbackUrlValidations] = useState<
    boolean[]
  >([false]);
  const [logoutUrlsValue, setLogoutUrlsValue] = useState<string[]>(['']);
  const [logoutUrlValidations, setLogoutUrlValidations] = useState<boolean[]>([
    false,
  ]);
  const [selectedAppClientId, setSelectedAppClientId] = useState<string>('');
  const [approver, setApprover] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [callbackUrlsToggle, setCallbackUrlsToggle] = useState(false);
  const [logoutUrlsToggle, setLogoutUrlsToggle] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [customScopesValue, setCustomScopesValue] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const envValue = SDLC_STAGES.PROD;

  useEffect(() => {
    if (accessToken) {
      fetchCustomScopesList(accessToken)
        .then(response => {
          if (response) {
            dispatch(setCustomScopes(response));
          } else {
            console.error('Scopes not found in response');
            dispatch(setCustomScopes([]));
          }
        })
        .catch(error => {
          console.error('Failed to fetch scopes:', error);
          dispatch(setCustomScopes([]));
        });
      fetchCognitoUserPoolClients(accessToken)
        .then(response => {
          if (response) {
            dispatch(setAppClients(response));
          } else {
            console.error('App Clients not found in response');
            dispatch(setAppClients({}));
          }
        })
        .catch(error => {
          console.error('Failed to fetch app clients:', error);
          dispatch(setAppClients({}));
        });
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (accessToken && selectedAppClientId !== '') {
      setLoading(true);

      fetchCognitoClientDetails(selectedAppClientId, accessToken)
        .then(response => {
          if (response) {
            dispatch(setAppClientDetails(response));
          } else {
            console.error('App Clients not found in response');
            dispatch(setAppClientDetails({} as CognitoAppClientDetails));
          }
        })
        .catch(error => {
          console.error('Failed to fetch app clients:', error);
          dispatch(setAppClientDetails({} as CognitoAppClientDetails));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (selectedAppClientId === '') {
      dispatch(setAppClientDetails({} as CognitoAppClientDetails));
    }
  }, [dispatch, accessToken, selectedAppClientId]);

  const customScopesList = useSelector((state: State) => state.customScopes);
  const appClientsArray = useSelector((state: State) =>
    Object.entries(state.appClients).map(([clientId, clientName]) => ({
      clientId,
      clientName,
    })),
  );
  const appClientDetailsDict = useSelector(
    (state: State) => state.appClientDetails,
  );

  useEffect(() => {
    if (appClientDetailsDict && appClientDetailsDict.AllowedOAuthScopes) {
      const initialScopes = appClientDetailsDict.AllowedOAuthScopes.filter(
        scope => customScopesList.includes(scope),
      );
      setCustomScopesValue(initialScopes);
    }
  }, [appClientDetailsDict, customScopesList]);

  useEffect(() => {
    if (appClientDetailsDict && appClientDetailsDict.CallbackURLs) {
      setCallbackUrlsValue(appClientDetailsDict.CallbackURLs);
      setCallbackUrlValidations(
        appClientDetailsDict.CallbackURLs.map(url => !validateURL(url)),
      );
    }
    if (appClientDetailsDict && appClientDetailsDict.LogoutURLs) {
      setLogoutUrlsValue(appClientDetailsDict.LogoutURLs);
      setLogoutUrlValidations(
        appClientDetailsDict.LogoutURLs.map(url => !validateURL(url)),
      );
    }
  }, [appClientDetailsDict]);

  const handleCallbackUrlChange = (index: number, event: any) => {
    const updatedCallbackUrls: string[] = [...callbackUrlsValue];
    updatedCallbackUrls[index] = event.target.value;
    setCallbackUrlsValue(updatedCallbackUrls);

    const updatedUrlValidations: boolean[] = [...callbackUrlValidations];
    updatedUrlValidations[index] = !validateURL(event.target.value);
    setCallbackUrlValidations(updatedUrlValidations);
  };

  const handleCallbackUrlAddFields = () => {
    setCallbackUrlsValue([...callbackUrlsValue, '']);
    setCallbackUrlValidations([...callbackUrlValidations, false]);
  };

  const handleCallbackUrlDeleteFields = (index: number) => {
    if (callbackUrlsValue.length > 1) {
      const updatedCallbackUrls = [...callbackUrlsValue];
      updatedCallbackUrls.splice(index, 1);
      setCallbackUrlsValue(updatedCallbackUrls);

      const updatedUrlValidations: boolean[] = [...callbackUrlValidations];
      updatedUrlValidations.splice(index, 1);
      setCallbackUrlValidations(updatedUrlValidations);
    } else {
      setCallbackUrlsValue(['']);
      setCallbackUrlValidations([false]);
    }
  };

  const handleLogoutUrlChange = (index: number, event: any) => {
    const updatedLogoutUrls: string[] = [...logoutUrlsValue];
    updatedLogoutUrls[index] = event.target.value;
    setLogoutUrlsValue(updatedLogoutUrls);

    const updatedUrlValidations: boolean[] = [...logoutUrlValidations];
    updatedUrlValidations[index] = !validateURL(event.target.value);
    setLogoutUrlValidations(updatedUrlValidations);
  };

  const handleLogoutUrlAddFields = () => {
    setLogoutUrlsValue([...logoutUrlsValue, '']);
    setLogoutUrlValidations([...logoutUrlValidations, false]);
  };

  const handleLogoutUrlDeleteFields = (index: number) => {
    if (logoutUrlsValue.length > 1) {
      const updatedlogoutUrls = [...logoutUrlsValue];
      updatedlogoutUrls.splice(index, 1);
      setLogoutUrlsValue(updatedlogoutUrls);

      const updatedUrlValidations: boolean[] = [...logoutUrlValidations];
      updatedUrlValidations.splice(index, 1);
      setLogoutUrlValidations(updatedUrlValidations);
    } else {
      setLogoutUrlsValue([]);
      setLogoutUrlValidations([false]);
    }
  };

  const handleCustomScopeChange = (event: any) => {
    setCustomScopesValue(event);
  };

  const handleReset = () => {
    setSelectedAppClientId('');
    setCallbackUrlsValue(['']);
    setCallbackUrlValidations([false]);
    setLogoutUrlsValue(['']);
    setLogoutUrlValidations([false]);
    setCustomScopesValue([]);
    setApprover('');
    setIsLoading(false);
    setDropdownToggle(false);
    setLogoutUrlsToggle(false);
    setCallbackUrlsToggle(false);
  };

  const handleCallbackUrlToggleChange = (event: any) => {
    setCallbackUrlsToggle(event.target.checked);
    if (!event.target.checked) {
      if (appClientDetailsDict && appClientDetailsDict.CallbackURLs) {
        setCallbackUrlsValue(appClientDetailsDict.CallbackURLs);
        setCallbackUrlValidations(
          appClientDetailsDict.CallbackURLs.map(url => !validateURL(url)),
        );
      } else {
        setCallbackUrlsValue(['']);
      }
    }
  };

  const handleLogoutUrlToggleChange = (event: any) => {
    setLogoutUrlsToggle(event.target.checked);
    if (!event.target.checked) {
      if (appClientDetailsDict && appClientDetailsDict.LogoutURLs) {
        setLogoutUrlsValue(appClientDetailsDict.LogoutURLs);
        setLogoutUrlValidations(
          appClientDetailsDict.LogoutURLs.map(url => !validateURL(url)),
        );
      } else {
        setLogoutUrlsValue(['']);
      }
    }
  };

  const handleDropdownToggleChange = (event: any) => {
    setDropdownToggle(!dropdownToggle);
    if (!event.target.checked) {
      if (appClientDetailsDict && appClientDetailsDict.AllowedOAuthScopes) {
        const initialScopes = appClientDetailsDict.AllowedOAuthScopes.filter(
          scope => customScopesList.includes(scope),
        );
        setCustomScopesValue(initialScopes);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const requestId = getRequestId();
    const appClientName = appClientsArray
      .find(client => client.clientId === selectedAppClientId)
      ?.clientName?.split(' ')[0];

    const requestMessage = `Request: Update cognito app client<br />
    Requester: ${email} <br />
    App Client Name: ${appClientName}<br />
    Client Id: ${selectedAppClientId}<br />
    Redirect URL(s): ${callbackUrlsValue}<br />
    Logout URL(s): ${logoutUrlsValue}<br />
    Custom Scope(s): <br />
    ${formatStringArray(customScopesValue)}
    `;

    const content: CognitoApplicationClientUpdateRequest = {
      requestId: requestId,
      requester: email,
      environment: envValue,
      clientId: selectedAppClientId,
      applicationName: appClientName ?? '',
      callbackURLs: callbackUrlsValue,
      logoutURLs: logoutUrlsValue,
      customScopes: customScopesValue,
    };

    const request = {
      requestId: requestId,
      requestType: APP_CLIENT_MODIFICATION_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: content,
    };

    await triggerRequest(request, accessToken);

    addNotifications(
      APP_CLIENT_MODIFICATION_FORM_NAME,
      `App client modification request sent to '${approver.toLowerCase()}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  const submitCheck = () => {
    let canSubmit =
      isLoading ||
      !approver ||
      !selectedAppClientId ||
      !(logoutUrlsToggle || dropdownToggle || callbackUrlsToggle);

    if (logoutUrlsToggle) {
      canSubmit =
        canSubmit ||
        logoutUrlsValue.some(logoutUrl => logoutUrl === '') ||
        logoutUrlValidations.some(validator => validator);
    }

    if (callbackUrlsToggle) {
      canSubmit =
        canSubmit ||
        callbackUrlsValue.some(callbackUrl => callbackUrl === '') ||
        callbackUrlValidations.some(validator => validator);
    }

    return canSubmit;
  };

  return (
    <Page themeId="tool">
      <Header
        title={APP_CLIENT_MODIFICATION_FORM_NAME}
        subtitle={APP_CLIENT_MODIFICATION_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <AsynchronousAutoComplete
                  id="client-autocomplete"
                  label="Cognito App Client Name"
                  options={appClientsArray}
                  getOptionLabel={option => option.clientName}
                  value={
                    appClientsArray.find(
                      client => client.clientId === selectedAppClientId,
                    ) || null
                  }
                  onChange={(_event: any, newValue: any) => {
                    setSelectedAppClientId(newValue ? newValue.clientId : '');
                    if (!newValue) {
                      handleReset();
                    }
                  }}
                />
                <FormHelperText id="app-client-helper-text">
                  Please select the existing Cognito App Client that needs to be
                  modified.
                </FormHelperText>
                {loading && (
                  <div>
                    <p>Loading...</p>
                    <progress />
                  </div>
                )}
                <br />
                {selectedAppClientId && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={callbackUrlsToggle}
                          onChange={handleCallbackUrlToggleChange}
                        />
                      }
                      label="Modify Callback URL(s)"
                    />
                    {callbackUrlsValue.map((data, index) => (
                      <>
                        <br />
                        <div key={index}>
                          <Stack spacing={2} direction="row">
                            <TextField
                              fullWidth
                              label="Callback URL"
                              placeholder="Callback URL"
                              variant="outlined"
                              onChange={(event: any) =>
                                handleCallbackUrlChange(index, event)
                              }
                              value={data}
                              required
                              error={callbackUrlValidations[index]}
                              helperText={
                                callbackUrlValidations[index]
                                  ? 'Enter valid URL, cognito app client callback supports http protocol with localhost url only.'
                                  : ''
                              }
                              disabled={!callbackUrlsToggle}
                            />
                            {callbackUrlsToggle && (
                              <ButtonGroup variant="contained" color="primary">
                                {callbackUrlsValue.length > 1 && (
                                  <Button
                                    aria-label="delete"
                                    onClick={() =>
                                      handleCallbackUrlDeleteFields(index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </Button>
                                )}
                                <Button
                                  aria-label="add"
                                  onClick={handleCallbackUrlAddFields}
                                >
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                            )}
                          </Stack>
                        </div>
                      </>
                    ))}
                    {selectedAppClientId && !callbackUrlsToggle && (
                      <FormHelperText id="callback-urls-toggle-off-helper-text">
                        Please enable the toggle, if you want to modify existing
                        list of Callback URL(s)
                      </FormHelperText>
                    )}
                    {selectedAppClientId && callbackUrlsToggle && (
                      <FormHelperText id="callback-urls-toggle-on-helper-text">
                        Please provide callback URL to modify existing list of
                        Callback URL(s) for the selected app client.
                      </FormHelperText>
                    )}
                    <br />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={dropdownToggle}
                          onChange={handleDropdownToggleChange}
                        />
                      }
                      label="Modify Custom Scope(s)"
                    />
                    <br />
                    <Autocomplete
                      multiple
                      options={customScopesList}
                      value={customScopesValue}
                      onChange={(_, newValue) =>
                        handleCustomScopeChange(newValue)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Custom Scopes"
                          variant="outlined"
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            style={{ marginRight: 8 }}
                          />
                          {option}
                        </li>
                      )}
                      disabled={!dropdownToggle}
                      disableCloseOnSelect
                    />
                    {selectedAppClientId && !dropdownToggle && (
                      <FormHelperText id="custom-scope-toggle-off-helper-text">
                        Please enable the toggle, if you want to modify existing
                        scopes.
                      </FormHelperText>
                    )}
                    {selectedAppClientId && dropdownToggle && (
                      <FormHelperText id="custom-scope-toggle-on-helper-text">
                        Please select the custom scopes to modify existing list
                        of custom scopes for the selected app client.
                      </FormHelperText>
                    )}
                    <br />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={logoutUrlsToggle}
                          onChange={handleLogoutUrlToggleChange}
                        />
                      }
                      label="Modify Logout URL(s)"
                    />

                    {logoutUrlsValue.map((data, index) => (
                      <>
                        <br />
                        <div key={index}>
                          <Stack spacing={2} direction="row">
                            <TextField
                              fullWidth
                              label="Logout URL"
                              placeholder="Logout URL"
                              variant="outlined"
                              onChange={event =>
                                handleLogoutUrlChange(index, event)
                              }
                              value={data}
                              required
                              error={logoutUrlValidations[index]}
                              helperText={
                                logoutUrlValidations[index]
                                  ? 'Enter valid URL, cognito app client Logout supports http protocol with localhost url only.'
                                  : ''
                              }
                              disabled={!logoutUrlsToggle} // Disable the text field when toggle is off
                            />
                            {logoutUrlsToggle && (
                              <ButtonGroup variant="contained" color="primary">
                                {logoutUrlsValue.length > 1 && (
                                  <Button
                                    aria-label="delete"
                                    onClick={() =>
                                      handleLogoutUrlDeleteFields(index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </Button>
                                )}
                                <Button
                                  aria-label="add"
                                  onClick={() => handleLogoutUrlAddFields()}
                                >
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                            )}
                          </Stack>
                        </div>
                      </>
                    ))}
                    {selectedAppClientId && !logoutUrlsToggle && (
                      <FormHelperText id="logout-urls-toggle-off-helper-text">
                        Please enable the toggle, if you want to modify existing
                        list of logout URL(s).
                      </FormHelperText>
                    )}
                    {selectedAppClientId && logoutUrlsToggle && (
                      <FormHelperText id="logout-urls-toggle-on-helper-text">
                        Please provide the logout URL to modify existing list of
                        logout URL(s) for the selected app client.
                      </FormHelperText>
                    )}
                    <br />
                  </>
                )}
                <BreadBoardAutoComplete
                  id="approver"
                  options={COGNITO_APPROVER_EMAILS}
                  value={approver}
                  onChange={(_event: any, newValue: any) => {
                    setApprover(newValue);
                  }}
                  label="Approver's Email"
                />
                <FormHelperText id="approver-helper-text">
                  Please choose an approver who will authorise the update of the
                  app client.
                </FormHelperText>
                <br />
              </FormControl>
              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
