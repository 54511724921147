import { InfoCard } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { JsonArray } from '@backstage/types';

interface ItemArray {
  items: JsonArray;
}

export const ListStack = ({ items }: ItemArray) => {
  const data = items.map(item => <li>{item}</li>);

  return <ul>{data}</ul>;
};

export const TechStackCard = () => {
  const emptyarr: JsonArray = [];
  const { entity } = useEntity();
  const tech_stack_arr = (entity.metadata.tech_stack || emptyarr) as JsonArray;

  return (
    <InfoCard title="Tech Stack" variant="gridItem">
      <Typography variant="body1">
        <ListStack items={tech_stack_arr} />
      </Typography>
    </InfoCard>
  );
};
