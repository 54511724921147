import { TableColumn, Table } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useEntity, catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { Entity } from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';

interface EntityArray {
  users: Entity[];
}

interface Params extends JsonObject {
  profile: {
    displayName: string;
    email: string;
  };
}

export const DenseTable = ({ users }: EntityArray) => {
  const columns: TableColumn[] = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Role', field: 'role' },
  ];

  const data = users.map(user => {
    const { displayName, email } = user.spec?.profile as Params;

    // const {displayName, email} = profile;

    return {
      name: displayName || 'DefaultName',
      email: email || 'DefaultEmail',
      role: user.spec?.role,
    };
  });

  return (
    <Table
      title="Members List"
      options={{ search: false, paging: false }}
      columns={columns}
      data={data}
    />
  );
};

export const GroupDetailsTab = () => {
  const { entity: groupEntity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const {
    metadata: { name: groupName, namespace: grpNamespace },
  } = groupEntity;
  const groupNamespace = grpNamespace || 'default';

  const { value } = useAsync(async () => {
    const membersList = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'relations.memberof': [
          stringifyEntityRef({
            kind: 'group',
            namespace: groupNamespace.toLocaleLowerCase('en-US'),
            name: groupName.toLocaleLowerCase('en-US'),
          }),
        ],
      },
    });

    return membersList.items;
  }, [catalogApi, groupEntity]);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <DenseTable users={value || []} />
      </Grid>
    </Grid>
  );
};
