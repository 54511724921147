/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useEffect, useState } from 'react';
import { Content, Header, InfoCard, Page } from '@backstage/core-components';

import { FormHelperText, FormControl, Grid, Checkbox } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  APP_CLIENT_CREATION_FORM_NAME,
  APP_CLIENT_CREATION_FORM_DESCRIPTION,
  COGNITO_APPROVER_EMAILS,
  APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH,
  APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH,
} from '../../../../../packages/app/src/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../../packages/app/src/store/types';
import {
  BreadBoardAutoComplete,
  BreadBoardButtonGroup,
  EnvironmentOptions,
} from '../common';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  fetchCustomScopesList,
  getRequestId,
  triggerRequest,
  validateScopeNameString,
  validateTitleCase,
  validateURL,
  BUTTON_COLOR,
  Environments,
  Scope,
  SDLC_STAGES,
  CognitoApplicationClientCreationRequest,
  formatScopes,
  formatStringArray,
} from '../../internal';
import { setCustomScopes } from '../../../../../packages/app/src/store/actions';

export const CentralIdentityAPIForm = () => {
  const dispatch = useDispatch();
  const email = useSelector((state: State) => state.email);
  const accessToken = useSelector((state: State) => state.accessToken);

  const [applicationNameValue, setApplicationNameValue] = useState<string>('');
  const [appClientName, setAppClientName] = useState<string>('');
  const [applicationNameValidation, setApplicationNameValidation] =
    useState<boolean>(false);
  const [callbackUrlsValue, setCallbackUrlsValue] = useState<string[]>(['']);
  const [callbackUrlValidations, setCallbackUrlValidations] = useState<
    boolean[]
  >([false]);
  const [callbackUrlValidationMessage, setCallbackUrlValidationMessage] =
    useState<string[]>(['']);
  const [logoutUrlsValue, setLogoutUrlsValue] = useState<string[]>(['']);
  const [logoutUrlValidations, setLogoutUrlValidations] = useState<boolean[]>([
    false,
  ]);
  const [
    resourceServerIdentifierNameValue,
    setResourceServerIdentifierNameValue,
  ] = useState('');
  const [approver, setApprover] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resourceServerScopes, setResourceServerScopes] = useState<Scope[]>([
    { ScopeName: '', ScopeDescription: '' },
  ]);
  const [resourceServerIdentifierToggle, setResourceServerIdentifierToggle] =
    useState(false);
  const [logoutUrlsToggle, setLogoutUrlsToggle] = useState(false);
  const [customScopesToggle, setCustomScopesToggle] = useState(false);
  const [customScopesValue, setCustomScopesValue] = useState<string[]>([]);
  const [scopeNamesValidation, setScopeNamesValidation] = useState<boolean[]>([
    false,
  ]);

  const environments: Environments = {
    Option1: {
      value: SDLC_STAGES.NON_PROD,
      label: SDLC_STAGES.NON_PROD,
      isVisible: true,
    },
    Option2: {
      value: SDLC_STAGES.QA,
      label: SDLC_STAGES.QA,
      isVisible: false,
    },
    Option3: {
      value: SDLC_STAGES.STAGE,
      label: SDLC_STAGES.STAGE,
      isVisible: false,
    },
    Option4: {
      value: SDLC_STAGES.UAT,
      label: SDLC_STAGES.UAT,
      isVisible: false,
    },
    Option5: {
      value: SDLC_STAGES.PROD,
      label: SDLC_STAGES.PROD,
      isVisible: true,
    },
  };

  const [envValue, setEnv] = useState<string>(environments.Option1.value); // Setting it to let to change envValue

  const onEnvChange = (event: any) => setEnv(event.target.value);

  useEffect(() => {
    setAppClientName(
      applicationNameValue
        ? `ctx-analogtools-idp-${envValue.toLowerCase()}-${applicationNameValue
            .toLowerCase()
            .replace(/\s+/g, '-')}-client`
        : '',
    );
    if (envValue === SDLC_STAGES.NON_PROD) {
      setResourceServerIdentifierNameValue(
        applicationNameValue
          ? `${applicationNameValue
              .toLowerCase()
              .replace(/\s+/g, '-')}-${envValue.toLowerCase()}`
          : '',
      );
    } else {
      setResourceServerIdentifierNameValue(
        applicationNameValue
          ? `${applicationNameValue.toLowerCase().replace(/\s+/g, '-')}`
          : '',
      );
    }
  }, [envValue, applicationNameValue]);

  useEffect(() => {
    if (accessToken) {
      fetchCustomScopesList(accessToken)
        .then(response => {
          if (response) {
            dispatch(setCustomScopes(response));
          } else {
            console.error('Scopes not found in response');
            dispatch(setCustomScopes([]));
          }
        })
        .catch(error => {
          console.error('Failed to fetch scopes:', error);
          dispatch(setCustomScopes([]));
        });
    }
  }, [dispatch, accessToken]);

  const customScopesList = useSelector((state: State) => state.customScopes);

  const handleScopeChange = (index: number, event: any, key: keyof Scope) => {
    const newScopes = [...resourceServerScopes];

    if (key === 'ScopeName') {
      const newScopeValidations = [...scopeNamesValidation];
      newScopeValidations[index] = !validateScopeNameString(event.target.value);
      setScopeNamesValidation(newScopeValidations);
    }

    newScopes[index][key] = event.target.value;
    setResourceServerScopes(newScopes);
  };

  const handleScopeAddFields = () => {
    setResourceServerScopes([
      ...resourceServerScopes,
      { ScopeName: '', ScopeDescription: '' },
    ]);
  };
  const handleScopeDeleteFields = (index: number) => {
    if (resourceServerScopes.length > 1) {
      const updatedScopes = [...resourceServerScopes];
      updatedScopes.splice(index, 1);
      setResourceServerScopes(updatedScopes);

      const updatedScopeNamesValidation: boolean[] = [...scopeNamesValidation];
      updatedScopeNamesValidation.splice(index, 1);
      setScopeNamesValidation(updatedScopeNamesValidation);
    } else {
      setResourceServerScopes([{ ScopeName: '', ScopeDescription: '' }]);
      setScopeNamesValidation([false]);
    }
  };

  const handleChange = (index: number, event: any) => {
    const callbackUrl = event.target.value;
    const updatedCallbackUrls: string[] = [...callbackUrlsValue];
    updatedCallbackUrls[index] = callbackUrl;
    setCallbackUrlsValue(updatedCallbackUrls);

    const updatedUrlValidations: boolean[] = [...callbackUrlValidations];
    const updatedUrlValidationMessage: string[] = [
      ...callbackUrlValidationMessage,
    ];
    if (!validateURL(callbackUrl)) {
      updatedUrlValidations[index] = true;
      updatedUrlValidationMessage[index] =
        'Enter valid URL, cognito app client callback supports http protocol with localhost url only.';
    } else {
      updatedUrlValidations[index] = false;
      updatedUrlValidationMessage[index] = '';
    }
    setCallbackUrlValidations(updatedUrlValidations);
    setCallbackUrlValidationMessage(updatedUrlValidationMessage);
  };

  const handleAddFields = () => {
    setCallbackUrlsValue([...callbackUrlsValue, '']);
    setCallbackUrlValidations([...callbackUrlValidations, false]);
  };

  const handleDeleteFields = (index: number) => {
    if (callbackUrlsValue.length > 1) {
      const updatedCallbackUrls = [...callbackUrlsValue];
      updatedCallbackUrls.splice(index, 1);
      setCallbackUrlsValue(updatedCallbackUrls);

      const updatedUrlValidations: boolean[] = [...callbackUrlValidations];
      updatedUrlValidations.splice(index, 1);
      setCallbackUrlValidations(updatedUrlValidations);
    } else {
      setCallbackUrlsValue(['']);
      setCallbackUrlValidations([false]);
    }
  };

  const handleLogoutUrlChange = (index: number, event: any) => {
    const updatedLogoutUrls: string[] = [...logoutUrlsValue];
    updatedLogoutUrls[index] = event.target.value;
    setLogoutUrlsValue(updatedLogoutUrls);

    const updatedUrlValidations: boolean[] = [...logoutUrlValidations];
    updatedUrlValidations[index] = !validateURL(event.target.value);
    setLogoutUrlValidations(updatedUrlValidations);
  };

  const handleLogoutUrlAddFields = () => {
    setLogoutUrlsValue([...logoutUrlsValue, '']);
    setLogoutUrlValidations([...logoutUrlValidations, false]);
  };

  const handleLogoutUrlDeleteFields = (index: number) => {
    if (logoutUrlsValue.length > 1) {
      const updatedlogoutUrls = [...logoutUrlsValue];
      updatedlogoutUrls.splice(index, 1);
      setLogoutUrlsValue(updatedlogoutUrls);

      const updatedUrlValidations: boolean[] = [...logoutUrlValidations];
      updatedUrlValidations.splice(index, 1);
      setLogoutUrlValidations(updatedUrlValidations);
    } else {
      setLogoutUrlsValue(['']);
      setLogoutUrlValidations([false]);
    }
  };

  const handleCustomScopeChange = (_: any, value: string[]) => {
    setCustomScopesValue(value);
  };

  const handleReset = () => {
    setApplicationNameValue('');
    setResourceServerIdentifierNameValue('');
    setAppClientName('');
    setCallbackUrlsValue(['']);
    setCallbackUrlValidations([false]);
    setLogoutUrlsValue(['']);
    setLogoutUrlValidations([false]);
    setCustomScopesValue([]);
    setApplicationNameValidation(false);
    setResourceServerScopes([{ ScopeName: '', ScopeDescription: '' }]);
    setApprover('');
    setEnv(environments.Option1.value);
    setIsLoading(false);
    setApplicationNameValidation(false);
    setCustomScopesToggle(false);
    setLogoutUrlsToggle(false);
    setResourceServerIdentifierToggle(false);
  };

  const onApplicationNameChange = (event: any) => {
    setApplicationNameValidation(
      !validateTitleCase(
        event.target.value,
        APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH,
        APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH,
      ),
    );
    setApplicationNameValue(event.target.value);
  };

  const handleResourceServerIdentifierChange = (event: any) => {
    setResourceServerIdentifierToggle(event.target.checked);
    if (!event.target.checked) {
      setResourceServerIdentifierNameValue('');
    } else {
      const lowerHyphenatedText = applicationNameValue
        .toLowerCase()
        .replace(/\s+/g, '-');
      setResourceServerIdentifierNameValue(lowerHyphenatedText);
    }
  };

  const handleLogoutUrlToggleChange = (event: any) => {
    setLogoutUrlsToggle(event.target.checked);
    if (!event.target.checked) {
      setLogoutUrlsValue(['']);
    }
  };

  const handleCustomScopesToggleChange = () => {
    setCustomScopesToggle(!customScopesToggle);
    if (!customScopesToggle) {
      setCustomScopesValue([]);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const requestId = getRequestId();

    let requestContent: CognitoApplicationClientCreationRequest;
    let requestMessage: string;

    // If resourceServerIdentifierToggle is enabled, attach scopes in the request message and request content
    if (resourceServerIdentifierToggle) {
      const formattedScopes = formatScopes(
        resourceServerIdentifierNameValue,
        resourceServerScopes,
      );

      // If logoutUrlsToggle is enabled, attach logoutUrlsValue in the request message and request content
      if (logoutUrlsToggle) {
        // If customScopesToggle is enabled, attach customScopes in the request message and request content
        if (customScopesToggle) {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Resource Sever Identifier Name: ${resourceServerIdentifierNameValue}<br />
          Custom Scope(s): <br/>
          ${formattedScopes}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          Logout URL(s): ${logoutUrlsValue}<br />
          Attach Existing Scope(s): <br />
          ${formatStringArray(customScopesValue)}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            identifier: resourceServerIdentifierNameValue,
            resourceServerScopes: resourceServerScopes,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
            logoutURLs: logoutUrlsValue,
            customScopes: customScopesValue, // actual value to be passed for creation
          };
        } else {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Resource Sever Identifier Name: ${resourceServerIdentifierNameValue}<br />
          Custom Scope(s): <br/>
          ${formattedScopes}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          Logout URL(s): ${logoutUrlsValue}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            identifier: resourceServerIdentifierNameValue,
            resourceServerScopes: resourceServerScopes,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
            logoutURLs: logoutUrlsValue,
          };
        }
      } else {
        // If customScopesToggle is enabled, attach customScopes in the request message and request content
        if (customScopesToggle) {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Resource Sever Identifier Name: ${resourceServerIdentifierNameValue}<br />
          Custom Scope(s): <br/>
          ${formattedScopes}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          Attach Existing Scope(s): <br />
          ${formatStringArray(customScopesValue)}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            identifier: resourceServerIdentifierNameValue,
            resourceServerScopes: resourceServerScopes,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
            customScopes: customScopesValue, // actual value to be passed for creation
          };
        } else {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Resource Sever Identifier Name: ${resourceServerIdentifierNameValue}<br />
          Custom Scope(s): <br/>
          ${formattedScopes}<br />
          Redirect URL(s): ${callbackUrlsValue}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            applicationName: applicationNameValue,
            identifier: resourceServerIdentifierNameValue,
            resourceServerScopes: resourceServerScopes,
            callbackURLs: callbackUrlsValue,
          };
        }
      }
    } else {
      // If logoutUrlsToggle is enabled, attach logoutUrlsValue in the request message and request content
      if (logoutUrlsToggle) {
        // If customScopesToggle is enabled, attach customScopes in the request message and request content
        if (customScopesToggle) {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          Logout URL(s): ${logoutUrlsValue}<br />
          Attach Existing Scope(s): <br />
          ${formatStringArray(customScopesValue)}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
            logoutURLs: logoutUrlsValue,
            customScopes: customScopesValue,
          };
        } else {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          Logout URL(s): ${logoutUrlsValue}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
            logoutURLs: logoutUrlsValue,
          };
        }
      } else {
        // If customScopesToggle is enabled, attach customScopes in the request message and request content
        if (customScopesToggle) {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          Attach Existing Scope(s): <br />
          ${formatStringArray(customScopesValue)}
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
            customScopes: customScopesValue,
          };
        } else {
          requestMessage = `Request: Create cognito app client<br />
          Requester: ${email}<br />
          Environment: ${envValue}<br />
          Application Name: ${applicationNameValue}<br />
          App Client Name: ${appClientName}<br />
          Redirect URL(s): ${callbackUrlsValue}<br />
          `;
          requestContent = {
            requestId: requestId,
            requester: email,
            environment: envValue,
            applicationName: applicationNameValue,
            callbackURLs: callbackUrlsValue,
          };
        }
      }
    }

    const request = {
      requestId: requestId,
      requestType: APP_CLIENT_CREATION_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: requestContent,
    };

    await triggerRequest(request, accessToken);

    addNotifications(
      APP_CLIENT_CREATION_FORM_NAME,
      `App client creation request sent to '${approver.toLowerCase()}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  const submitCheck = () => {
    let canSubmit =
      isLoading ||
      !applicationNameValue ||
      !approver ||
      applicationNameValidation;

    if (resourceServerIdentifierToggle) {
      canSubmit =
        canSubmit ||
        scopeNamesValidation.some(validator => validator) ||
        resourceServerScopes.some(
          scope => scope.ScopeName === '' || scope.ScopeDescription === '',
        );
    }

    if (logoutUrlsToggle) {
      canSubmit =
        canSubmit ||
        logoutUrlsValue.some(logoutUrl => logoutUrl === '') ||
        logoutUrlValidations.some(validator => validator);
    }

    if (customScopesToggle) {
      canSubmit = canSubmit || customScopesValue.length === 0;
    }

    for (const callbackUrlValue of callbackUrlsValue) {
      canSubmit = canSubmit || callbackUrlValue === '';
    }

    for (const callbackUrlValidation of callbackUrlValidations) {
      if (callbackUrlValidation) {
        canSubmit = canSubmit || true;
      }
    }

    return canSubmit;
  };

  return (
    <Page themeId="tool">
      <Header
        title={APP_CLIENT_CREATION_FORM_NAME}
        subtitle={APP_CLIENT_CREATION_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <EnvironmentOptions
                  label="Environment"
                  value={envValue}
                  onChange={onEnvChange}
                  environments={environments}
                />
                <br />
                <TextField
                  label="Application Name"
                  variant="outlined"
                  placeholder="Application Name"
                  onChange={onApplicationNameChange}
                  error={applicationNameValidation}
                  helperText={
                    applicationNameValidation
                      ? 'Enter application name in title case with alphabets only.'
                      : ''
                  }
                  value={applicationNameValue}
                  required
                />
                <FormHelperText id="application-name-helper-text">
                  The application name should be in title case, E.g. "My App".
                  <br />
                  The application name must be at least{' '}
                  {APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH} characters long
                  and cannot exceed {APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH}{' '}
                  characters.
                  <br />
                </FormHelperText>
                <br />
                <TextField
                  label="App Client Name"
                  variant="outlined"
                  placeholder="App Client Name"
                  value={appClientName}
                  disabled
                />
                <FormHelperText id="app-client-name-helper-text">
                  The app client name will be a lowercased and hypenated version
                  of the application name specified above with a prefix
                  `ctx-analogtools-idp-` and postfix `-client`
                  <br />
                  E.g. For "ctx-analogtools-idp-my-app-client-client".
                  <br />
                </FormHelperText>
                {callbackUrlsValue.map((data, index) => (
                  <>
                    <br />
                    <Stack key={index} spacing={2} direction="row">
                      <TextField
                        fullWidth
                        label="Callback URL"
                        placeholder="Callback URL"
                        variant="outlined"
                        onChange={(event: any) => handleChange(index, event)}
                        value={data}
                        required
                        error={callbackUrlValidations[index]}
                        helperText={callbackUrlValidationMessage[index]}
                      />

                      <ButtonGroup
                        variant="contained"
                        color={BUTTON_COLOR.PRIMARY}
                      >
                        {callbackUrlsValue.length > 1 && (
                          <Button
                            aria-label="delete"
                            onClick={() => handleDeleteFields(index)}
                          >
                            <DeleteIcon />
                          </Button>
                        )}
                        <Button
                          aria-label="add"
                          onClick={() => handleAddFields()}
                        >
                          <AddIcon />
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </>
                ))}
                <FormHelperText id="callback-url-helper-text">
                  Please provide the callback URL to be added for the new app
                  client.
                </FormHelperText>
                <br />

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Advanced Options</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: '100%' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={resourceServerIdentifierToggle}
                            onChange={handleResourceServerIdentifierChange}
                          />
                        }
                        label="Create Resource Server"
                      />
                      {resourceServerIdentifierToggle && (
                        <TextField
                          label="Resource Server Identifier Name"
                          variant="outlined"
                          placeholder="Resource Server Identifier Name"
                          value={resourceServerIdentifierNameValue}
                          disabled
                          fullWidth
                        />
                      )}
                      <br />
                      {resourceServerIdentifierToggle && (
                        <FormHelperText id="resource-identfier-helper-text">
                          The resource server identifier name will be a
                          lowercased and hypenated version of the application
                          name specified above,
                          <br />
                          E.g. For "my-app".
                          <br />
                        </FormHelperText>
                      )}
                      {resourceServerIdentifierToggle &&
                        resourceServerScopes.map((scope, index) => (
                          <div key={index}>
                            <br />
                            <Stack spacing={2} direction="row">
                              <TextField
                                fullWidth
                                label="Scope name"
                                placeholder="Scope name"
                                variant="outlined"
                                onChange={event =>
                                  handleScopeChange(index, event, 'ScopeName')
                                }
                                value={scope.ScopeName}
                                required
                                error={scopeNamesValidation[index]}
                                helperText={
                                  scopeNamesValidation[index]
                                    ? 'Enter any characters except whitespace (s), double quotes (") or slashes (/).'
                                    : ''
                                }
                              />

                              <TextField
                                fullWidth
                                label="Scope description"
                                placeholder="Scope description"
                                variant="outlined"
                                onChange={event =>
                                  handleScopeChange(
                                    index,
                                    event,
                                    'ScopeDescription',
                                  )
                                }
                                value={scope.ScopeDescription}
                                required
                              />

                              <ButtonGroup variant="contained" color="primary">
                                {resourceServerScopes.length > 1 && (
                                  <Button
                                    aria-label="delete"
                                    onClick={() =>
                                      handleScopeDeleteFields(index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </Button>
                                )}
                                <Button
                                  aria-label="add"
                                  onClick={() => handleScopeAddFields()}
                                >
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                            </Stack>
                          </div>
                        ))}
                      {resourceServerIdentifierToggle && (
                        <FormHelperText id="scope-helper-text">
                          Please input the scope name, description pair(s) to be
                          included along with the new resource server.
                        </FormHelperText>
                      )}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={customScopesToggle}
                            onChange={handleCustomScopesToggleChange}
                          />
                        }
                        label="Attach Custom Scope(s)"
                      />
                      {customScopesToggle && (
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          options={customScopesList}
                          value={customScopesValue}
                          onChange={handleCustomScopeChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Custom Scopes"
                              placeholder={
                                customScopesValue.length === 0
                                  ? 'Select custom scopes'
                                  : ''
                              }
                            />
                          )}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                        />
                      )}
                      {customScopesToggle && (
                        <FormHelperText id="custom-scopes-helper-text">
                          Please select required custom scopes.
                        </FormHelperText>
                      )}
                      <br />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={logoutUrlsToggle}
                            onChange={handleLogoutUrlToggleChange}
                          />
                        }
                        label="Create Logout URL(s)"
                      />
                      {logoutUrlsToggle &&
                        logoutUrlsValue.map((data, index) => (
                          <>
                            <br />
                            <Stack key={index} spacing={2} direction="row">
                              <TextField
                                fullWidth
                                label="Logout URL"
                                placeholder="Logout URL"
                                variant="outlined"
                                onChange={(event: any) =>
                                  handleLogoutUrlChange(index, event)
                                }
                                value={data}
                                required
                                error={logoutUrlValidations[index]}
                                helperText={
                                  logoutUrlValidations[index]
                                    ? 'Enter valid URL, cognito app client Logout supports http protocol with localhost url only.'
                                    : ''
                                }
                              />

                              <ButtonGroup variant="contained" color="primary">
                                {logoutUrlsValue.length > 1 && (
                                  <Button
                                    aria-label="delete"
                                    onClick={() =>
                                      handleLogoutUrlDeleteFields(index)
                                    }
                                  >
                                    <DeleteIcon />
                                  </Button>
                                )}
                                <Button
                                  aria-label="add"
                                  onClick={() => handleLogoutUrlAddFields()}
                                >
                                  <AddIcon />
                                </Button>
                              </ButtonGroup>
                            </Stack>
                          </>
                        ))}
                    </div>
                    {logoutUrlsToggle && (
                      <FormHelperText id="logout-url-helper-text">
                        Please provide the logout URL to be added for the new
                        app client.
                      </FormHelperText>
                    )}
                  </AccordionDetails>
                </Accordion>
                <FormHelperText id="advanced-options-helper-text">
                  Please review these options before submitting request.
                </FormHelperText>
                <br />
                <BreadBoardAutoComplete
                  id="approver"
                  options={COGNITO_APPROVER_EMAILS}
                  value={approver}
                  onChange={(_event: any, newValue: any) => {
                    setApprover(newValue);
                  }}
                  label="Approver's Email"
                />
                <FormHelperText id="approver-helper-text">
                  Please choose an approver who will authorise the creation of
                  the app client.
                </FormHelperText>
                <br />
              </FormControl>
              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
