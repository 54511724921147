/* eslint-disable @backstage/no-forbidden-package-imports */
/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Content,
  ErrorPage,
  Header,
  InfoCard,
  Page,
} from '@backstage/core-components';

import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';

import {
  REGISTER_NEW_SSO_USER_FORM,
  REGISTER_NEW_SSO_USER_DESCRIPTION,
  ADMIN_EMAILS,
} from '../../../../../packages/app/src/common/constants';

import {
  BreadBoardButtonGroup,
  validateEmail,
} from '@internal/plugin-self-service';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { State } from '../../../../../packages/app/src/store/types';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import { createUserEntry } from '../../internal/utils';
import { BUTTON_COLOR } from '@internal/plugin-self-service/src/internal';

export const UsersForm = () => {
  const email = useSelector((state: State) => state.email);

  const accessToken = useSelector((state: State) => state.accessToken);
  const isAdmin = ADMIN_EMAILS.includes(email);

  const [emailIds, setEmailIds] = useState<string[]>(['']);
  const [emailIdsValidation, setEmailIdsValidation] = useState<boolean[]>([
    false,
  ]);
  const [emailIdsValidationMessage, setEmailIdsValidationMessage] = useState<
    string[]
  >(['']);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = async (index: number, event: any) => {
    const emailId = event.target.value;

    const updatedEmailIds: string[] = [...emailIds];

    updatedEmailIds[index] = emailId;

    const updatedEmailIdsValidation: boolean[] = [...emailIdsValidation];
    const updatedEmailIdsValidationMessage: string[] = [
      ...emailIdsValidationMessage,
    ];

    if (!validateEmail(emailId)) {
      updatedEmailIdsValidation[index] = true;
      updatedEmailIdsValidationMessage[index] =
        'Enter Valid Email with analog.com domain.';
    } else {
      updatedEmailIdsValidation[index] = false;
      updatedEmailIdsValidationMessage[index] = '';
    }

    setEmailIdsValidation(updatedEmailIdsValidation);
    setEmailIdsValidationMessage(updatedEmailIdsValidationMessage);

    setEmailIds(updatedEmailIds);
  };

  const handleAddFields = () => {
    setEmailIds([...emailIds, '']);
    setEmailIdsValidation([...emailIdsValidation, false]);
  };

  const handleDeleteFields = (index: number) => {
    if (emailIds.length > 1) {
      const updatedEmailIds = [...emailIds];
      updatedEmailIds.splice(index, 1);
      setEmailIds(updatedEmailIds);

      const updatedEmailIdsValidation: boolean[] = [...emailIdsValidation];
      updatedEmailIdsValidation.splice(index, 1);
      setEmailIdsValidation(updatedEmailIdsValidation);
    } else {
      setEmailIds(['']);
      setEmailIdsValidation([false]);
    }
  };

  const handleReset = () => {
    setEmailIds(['']);
    setEmailIdsValidation([false]);
    setEmailIdsValidationMessage(['']);
    setIsLoading(false);
  };

  const submitCheck = () => {
    let canSubmit = isLoading || emailIds[0] === '';

    for (const emailIdValidation of emailIdsValidation) {
      if (emailIdValidation) {
        canSubmit = canSubmit || true;
      }
    }
    return canSubmit;
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const response = await createUserEntry(
      {
        emails: emailIds,
      },
      accessToken,
    );

    if (response === 200) {
      addNotifications(
        REGISTER_NEW_SSO_USER_FORM,
        `New sso users '${emailIds}' added in the database.`,
        'success',
      );
    } else {
      addNotifications(
        REGISTER_NEW_SSO_USER_FORM,
        'Error, Failed to insert sso users!',
        'danger',
      );
    }

    setIsLoading(false);

    handleReset();
  };

  return isAdmin ? (
    <Page themeId="tool">
      <Header
        title={REGISTER_NEW_SSO_USER_FORM}
        subtitle={REGISTER_NEW_SSO_USER_DESCRIPTION}
      />
      <Content>
        <Grid container xs={12} md={6} spacing={3} direction="column">
          <InfoCard title={REGISTER_NEW_SSO_USER_FORM}>
            <FormControl variant="standard" fullWidth margin="dense">
              {emailIds.map((data, index) => (
                <>
                  <br />
                  <Stack key={index} spacing={4} direction="row">
                    <TextField
                      error={emailIdsValidation[index]}
                      fullWidth
                      label="Email Id"
                      placeholder="Email Id"
                      variant="outlined"
                      onChange={(event: any) => handleChange(index, event)}
                      required
                      value={data}
                      helperText={
                        emailIdsValidation[index]
                          ? emailIdsValidationMessage[index]
                          : ''
                      }
                    />

                    <ButtonGroup
                      variant="contained"
                      color={BUTTON_COLOR.PRIMARY}
                    >
                      <Button
                        aria-label="delete"
                        onClick={() => handleDeleteFields(index)}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        aria-label="add"
                        onClick={() => handleAddFields()}
                      >
                        <AddIcon />
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </>
              ))}
              <FormHelperText id="callback-url-helper-text">
                Email id for new sso user.
              </FormHelperText>
              <br />
            </FormControl>

            <BreadBoardButtonGroup
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              submitCheck={submitCheck}
              isLoading={isLoading}
            />
          </InfoCard>
        </Grid>
      </Content>
    </Page>
  ) : (
    <ErrorPage status="404" statusMessage="Page Not Found" />
  );
};
