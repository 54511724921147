export const validateTitleCase = (
  input: string,
  minLength: number,
  maxLength: number,
): boolean => {
  if (input.length >= 1) {
    const pattern = /^\s*([A-Z][a-z]*\s*)*$/;
    return (
      pattern.test(input) &&
      input.length >= minLength &&
      input.length <= maxLength
    );
  }
  return true;
};

export const validateAccountName = (
  input: string,
  env: string,
  minLength: number,
  maxLength: number,
  accountsList: any[],
) => {
  if (input.length >= 1) {
    const itemIndex = accountsList.findIndex(item => {
      return item.name === `${input} ${env}`;
    });
    return validateTitleCase(input, minLength, maxLength) && itemIndex < 0;
  }
  return true;
};

export const validateEmail = (input: string): boolean => {
  if (input.length >= 1) {
    const pattern = /^[a-zA-Z0-9._%+-]+@analog+\.com/;
    return pattern.test(input);
  }
  return true;
};

export const validateUserEmail = (input: string, list: any[]): boolean => {
  if (input.length >= 1) {
    const itemIndex = list.findIndex(item => {
      return item.emailId === input;
    });

    return validateEmail(input) && itemIndex >= 0;
  }
  return true;
};

export const validateDescription = (input: string): boolean => {
  const pattern = /^[a-zA-Z0-9\s_.,:/=+@-]*$/;

  return pattern.test(input) && input.length <= 256;
};

export const validateURL = (url: string): boolean => {
  // Match the URL against the regular expression.
  if (url.length >= 1) {
    const httpPattern = /^((http):\/\/)(localhost:\d{1,5}).*$/;
    const httpsPattern =
      /^((https):\/\/)?(www\.)?([a-zA-Z0-9\-\.]+)\.[a-zA-Z]{2,}(:\d{1,5})?(\/(.*))?$/;

    // The URL is valid, return true.
    if (url.startsWith('https://')) {
      return httpsPattern.test(url);
    } else if (url.startsWith('http://')) {
      return httpPattern.test(url);
    }
    return false;
  }
  return true;
};

export const validateLowerCaseHyphenSeparatedString = (
  input: string,
  minLength: number,
  maxLength: number,
): boolean => {
  if (input.length >= 1) {
    const pattern = /^[a-z][a-z0-9\-_]*$/;
    return (
      pattern.test(input) &&
      input.length >= minLength &&
      input.length <= maxLength
    );
  }
  return true;
};

export const validateScopeNameString = (input: string): boolean => {
  if (input.length >= 1) {
    const pattern = /^[^\s"'\\/]+$/;
    return pattern.test(input) && input.length <= 256;
  }
  return true;
};

export function validateIdentifierString(identifier: string): boolean {
  // Length constraints validation
  const isValidLength = identifier.length >= 1 && identifier.length <= 256;

  // Pattern constraints validation (ASCII characters from the pattern provided)
  const pattern = /^[\x21-\x7E]+$/;
  const matchesPattern = pattern.test(identifier);

  let isValidUrl = true;
  if (identifier.startsWith('http://') || identifier.startsWith('https://')) {
    isValidUrl = validateURL(identifier);
  }

  return isValidLength && matchesPattern && isValidUrl;
}

export function validateResourceServerString(identifier: string): boolean {
  if (identifier.length < 1 || identifier.length > 256) return false;
  const pattern = /^[\w\s+=,.@-]+$/;

  if (!pattern.test(identifier)) return false;

  return true;
}
