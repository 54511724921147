import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { SignInPage } from '@backstage/core-components';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { Root } from './components/Root';
import { apis, awsCognitoAuthApiRef } from './apis';
import { LighthousePage } from '@backstage/plugin-lighthouse';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';

import {
  SelfServicePage,
  HomePage,
  WorkloadAccountRequestForm,
  DevAccountRequestForm,
  CentralIdentityAPIForm,
  CognitoResourceServerCreationForm,
  CognitoAppClientModificationForm,
  SubDomainForm,
  AwsUserAccessForm,
  DeployRoleForm,
} from '@internal/plugin-self-service';

import { SquadPluginPage } from '@internal/plugin-squad-plugin';
import { CssBaseline } from '@material-ui/core';
import { UnifiedThemeProvider } from '@backstage/theme';

import LightIcon from '@material-ui/icons/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { darkTheme, lightTheme } from './customTheme/customTheme';
import { ReactNotifications } from 'react-notifications-component';
import {
  AdminPage,
  AvailableEmailForm,
  UsersForm,
  UsersTablePage,
} from '@internal/plugin-admin';
import 'react-notifications-component/dist/theme.css';

const IDENTITY_PROVIDERS = [
  {
    id: 'oauth2',
    title: 'AnalogB2C',
    message: 'Sign in using Analog B2C',
    apiRef: awsCognitoAuthApiRef,
  },
];

const app = createApp({
  apis,
  themes: [
    {
      id: 'dark-theme',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkModeIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={darkTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'light-theme',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={lightTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage {...props} providers={IDENTITY_PROVIDERS} align="center" />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1200} height={550} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/home" element={<HomePage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/aws-workload-account-request"
      element={<WorkloadAccountRequestForm />}
    />
    <Route
      path="/aws-dev-account-request"
      element={<DevAccountRequestForm />}
    />
    <Route path="/squad-plugin" element={<SquadPluginPage />} />
    <Route path="/central-identity-app" element={<CentralIdentityAPIForm />} />
    <Route
      path="/cognito-app-client-modification"
      element={<CognitoAppClientModificationForm />}
    />
    <Route
      path="/cognito-resource-server-create"
      element={<CognitoResourceServerCreationForm />}
    />
    <Route path="/lighthouse" element={<LighthousePage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/domain" element={<SubDomainForm />} />
    <Route path="/aws-user-access" element={<AwsUserAccessForm />} />
    <Route path="/aws-deploy-role" element={<DeployRoleForm />} />
    <Route
      path="/self-service"
      element={<SelfServicePage pageType="forms" />}
    />
    <Route
      path="/self-service/approvals/:requestId"
      element={<SelfServicePage pageType="approvals" />}
    />
    <Route path="/admin" element={<AdminPage />} />
    <Route path="/admin/users" element={<UsersTablePage />} />
    <Route path="/admin/create-users" element={<UsersForm />} />
    <Route path="/admin/insert-email" element={<AvailableEmailForm />} />
  </FlatRoutes>
);

export default app.createRoot(
  <Provider store={store}>
    <ReactNotifications />
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </Provider>,
);
