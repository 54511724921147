/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';

import {
  Header,
  Page,
  Content,
  ContentHeader,
} from '@backstage/core-components';

import { StationPage } from './StationComponent';
import { SubmissionsPage } from './SubmissionsComponent';
import { ApprovalsPage } from './ApprovalsComponent';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FeedIcon from '@mui/icons-material/Feed';
import Approval from '@mui/icons-material/Approval';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { SupportButton } from './SupportButton';

export const SelfService = ({ pageType }: { pageType: string }) => {
  const [value, setValue] = React.useState<string>(pageType);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Page themeId="service">
      <Header title="Self-Service" subtitle="Powered by AnvilOps" />
      <Content>
        <ContentHeader title="">
          <SupportButton
            title="Raise a Jira Ticket, if you face any issue with AnvilOps.<br/>
          Note: Jira will be accessed only within ADI Network."
          />
        </ContentHeader>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} centered>
                <Tab
                  icon={<FeedIcon />}
                  iconPosition="start"
                  label="Self-Service Station"
                  value="forms"
                />
                <Tab
                  icon={<Approval />}
                  iconPosition="start"
                  label="Approvals"
                  value="approvals"
                />
                <Tab
                  icon={<PendingActionsIcon />}
                  iconPosition="start"
                  label="Submissions"
                  value="submissions"
                />
              </TabList>
            </Box>
            <TabPanel value="forms">
              <StationPage />
            </TabPanel>
            <TabPanel value="approvals">
              <ApprovalsPage />
            </TabPanel>
            <TabPanel value="submissions">
              <SubmissionsPage />
            </TabPanel>
          </TabContext>
        </Box>
      </Content>
    </Page>
  );
};
