/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, FormControl } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FlakyIcon from '@mui/icons-material/Flaky';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { State } from '../../../../../../packages/app/src/store/types';
import {
  getPendingRequests,
  ANVILOPS_REQUEST_STATES,
  ANVILOPS_REQUEST_STATES_LABEL,
  BUTTON_COLOR,
  REQUEST_TYPE,
  Request,
} from '../../../internal';
import { SubmissionsTable } from './SubmissionsTable';

export const SubmissionsPage = () => {
  const accessToken = useSelector((state: State) => state.accessToken);
  const email = useSelector((state: State) => state.email);

  const [filteredPendingRequests, setFilteredPendingRequests] =
    useState<number>(0);
  const [filteredApprovedRequests, setFilteredApprovedRequests] =
    useState<number>(0);
  const [filteredRejectedRequests, setFilteredRejectedRequests] =
    useState<number>(0);
  const [filteredCompletedRequests, setFilteredCompletedRequests] =
    useState<number>(0);
  const [filteredInProgressRequests, setFilteredInProgressRequests] =
    useState<number>(0);
  const [requests, setRequests] = useState<Request[]>([]);

  useEffect(() => {
    async function getRequesterRequests() {
      if (accessToken) {
        const pendingRequestsResponse = await getPendingRequests(
          email,
          REQUEST_TYPE.REQUESTER,
          accessToken,
        );

        setRequests(pendingRequestsResponse.data);

        const pendingRequests = pendingRequestsResponse.data.filter(
          (request: Request) =>
            request.requestStatus === ANVILOPS_REQUEST_STATES.PENDING,
        );
        setFilteredPendingRequests(pendingRequests.length);

        const approvedRequests = pendingRequestsResponse.data.filter(
          (request: Request) =>
            request.requestStatus === ANVILOPS_REQUEST_STATES.APPROVED,
        );
        setFilteredApprovedRequests(approvedRequests.length);

        const rejectedRequests = pendingRequestsResponse.data.filter(
          (request: Request) =>
            request.requestStatus === ANVILOPS_REQUEST_STATES.REJECTED,
        );
        const failedRequests = pendingRequestsResponse.data.filter(
          (request: Request) =>
            request.requestStatus === ANVILOPS_REQUEST_STATES.WORKFLOW_FAILED ||
            request.requestStatus ===
              ANVILOPS_REQUEST_STATES.WORKFLOW_TRIGGER_FAILED ||
            request.requestStatus === ANVILOPS_REQUEST_STATES.REQUEST_FAILED ||
            request.requestStatus ===
              ANVILOPS_REQUEST_STATES.REQUEST_QUEUEING_FAILED ||
            request.requestStatus ===
              ANVILOPS_REQUEST_STATES.ACCOUNT_CUSTOMIZATION_FAILED ||
            request.requestStatus ===
              ANVILOPS_REQUEST_STATES.ACCOUNT_EMAIL_NOT_AVAILABLE,
        );
        setFilteredRejectedRequests(
          rejectedRequests.length + failedRequests.length,
        );

        const completedRequests = pendingRequestsResponse.data.filter(
          (request: Request) =>
            request.requestStatus === ANVILOPS_REQUEST_STATES.COMPLETED,
        );
        setFilteredCompletedRequests(completedRequests.length);
        setFilteredInProgressRequests(
          pendingRequestsResponse.data.length -
            (pendingRequests.length +
              approvedRequests.length +
              rejectedRequests.length +
              failedRequests.length +
              completedRequests.length),
        );
      }
    }

    getRequesterRequests();
  }, [accessToken, email]);

  return (
    <>
      <FormControl fullWidth margin="normal" required>
        <Stack spacing={2} direction="row">
          <Box width="20%">
            <Button
              fullWidth
              className="button-pending"
              variant="contained"
              color={BUTTON_COLOR.WARNING}
              endIcon={<FlakyIcon />}
            >
              {ANVILOPS_REQUEST_STATES_LABEL.WAITING_APPROVAL}&nbsp;&nbsp;(
              {filteredPendingRequests})
            </Button>
          </Box>
          <Box width="20%">
            <Button
              fullWidth
              className="button-approved"
              variant="contained"
              color={BUTTON_COLOR.SUCCESS}
              endIcon={<CheckCircleIcon />}
            >
              {ANVILOPS_REQUEST_STATES_LABEL.APPROVED}&nbsp;&nbsp;(
              {filteredApprovedRequests})
            </Button>
          </Box>
          <Box width="20%">
            <Button
              fullWidth
              className="button-rejected"
              variant="contained"
              color={BUTTON_COLOR.ERROR}
              endIcon={<CancelRoundedIcon />}
            >
              {ANVILOPS_REQUEST_STATES_LABEL.REJECTED}/
              {ANVILOPS_REQUEST_STATES_LABEL.FAILED}&nbsp;&nbsp;(
              {filteredRejectedRequests})
            </Button>
          </Box>
          <Box width="20%">
            <Button
              fullWidth
              className="button-in-progress"
              variant="contained"
              color={BUTTON_COLOR.INFO}
              endIcon={<AccessTimeFilledIcon />}
            >
              {ANVILOPS_REQUEST_STATES_LABEL.IN_PROGRESS}&nbsp;&nbsp;(
              {filteredInProgressRequests})
            </Button>
          </Box>
          <Box width="20%">
            <Button
              fullWidth
              className="button-completed"
              variant="contained"
              color={BUTTON_COLOR.SECONDARY}
              endIcon={<PublishedWithChangesIcon />}
            >
              {ANVILOPS_REQUEST_STATES_LABEL.COMPLETED}&nbsp;&nbsp;(
              {filteredCompletedRequests})
            </Button>
          </Box>
        </Stack>
        <br />
        <br />
        <SubmissionsTable data={requests} />
      </FormControl>
    </>
  );
};
